import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ScheduleEvent} from '../shared/scheduleEvent.interface';

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss']
})
export class DetailViewComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();
  scheduleEvent: ScheduleEvent;

  constructor() { }

  ngOnInit(): void {
    this.scheduleEvent = JSON.parse(sessionStorage.getItem('detail_schedule_event')) as ScheduleEvent;
    console.log("Current Schedule Event: ", this.scheduleEvent);
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
