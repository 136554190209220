<div fxFlex fxLayout="column" class="content-wrap">
  <!-- Toolbar -->
  <mat-toolbar>
    <app-menu-button fxLayout="column" class="menu-button"></app-menu-button>

    <div fxFlex.gt-sm fxLayout="row">
      <h2 fxFlex.gt-sm class="title">PTO Request</h2>

      <button *ngIf="showPTORequestHistory" mat-button class="primary-color history-button" routerLink="./../pto-history">
        <mat-icon class="icon-history"></mat-icon>
        <span fxHide fxShow.gt-sm>PTO History</span>
      </button>
    </div>
  </mat-toolbar>

  <!-- Content -->
  <div fxFlex class="content">
    <div class="pto-info">
      <div class="inner-container" fxLayout="row">
        <div fxFlex="50" class="pto-info-item">
          <div fxLayout="column" *ngIf="userProfile">
            <span class="label">Your PTO Balance</span>
            <p>
              <span class="balance-value">{{userProfile.ptoBalance | numberToTime }}</span>
              hours
            </p>
            <div class="subtitle" *ngIf="false">
              <span>as of Sep 23, 2020</span>
            </div>
          </div>
        </div>

        <div fxFlex="50" class="pto-info-item">
          <div fxLayout="column" *ngIf="userProfile">
            <span class="label">Pay Period</span>
            <p>
              <span class="balance-value">{{payPeriod.pay_period_start_date}}</span>
            </p>
            <p>
              <span class="balance-value">{{payPeriod.pay_period_end_date}}</span>
            </p>
          </div>
        </div>


        <!-- <div fxFlex="50" class="pto-info-item" *ngIf="false">
          <span class="label">Total requested</span>
          <p>
            <span class="balance-value">8</span>
            hours
          </p>
        </div> -->

      </div>
    </div>

    <div class="progress" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>

    <div class="message" *ngIf="message">
      <span>{{message}}</span>
    </div>

    <form *ngIf="!loading" [formGroup]="ptoForm" (ngSubmit)="onSubmit(ptoForm)">
      <div fxLayout="column" class="inner-container request-form">
        <mat-checkbox formControlName="isRange" (change)="toggleIsRange()">Select Date Range</mat-checkbox>
        <mat-checkbox *ngIf="ptoForm.value.isRange" formControlName="excludeWeekend" (change)="calcDayTotal()">Exclude Weekends</mat-checkbox>
        <div fxLayout="row">
          
          <mat-form-field *ngIf="!ptoForm.value.isRange" fxFlex="65" appearance="standard">
            <mat-label *ngIf="!ptoForm.value.isRange">Date</mat-label>
            <label>
              <input matInput readonly formControlName="ptoDate" [matDatepicker]="picker1" placeholder="Date">
            </label>
            <mat-datepicker-toggle matSuffix [for]="picker1">
              <mat-icon matDatepickerToggleIcon class="icon-calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker1 color="accent"></mat-datepicker>
          </mat-form-field>

          <mat-form-field *ngIf="ptoForm.value.isRange" fxFlex="65" appearance="standard">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input formGroupName="range" [rangePicker]="picker2" [dateFilter]="weekendsDatesFilter">
              <input matStartDate matInput formControlName="startDate" placeholder="Start date" readonly="readonly">
              <input matEndDate matInput formControlName="endDate" placeholder="End date" readonly="readonly" (dateChange)="calcDayTotal()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker2">
              <mat-icon matDatepickerToggleIcon class="icon-calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>

            <mat-error *ngIf="ptoForm.controls['range'].controls['startDate'].hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="ptoForm.controls['range'].controls['endDate'].hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>

          <div class="error" *ngIf="range?.hasError('startEndCompare')">
            <span>Start Date must be before End Date</span>
          </div>

          <mat-form-field fxFlex="15"  fxFlexOffset="5" appearance="standard">
            <mat-label>Hrs</mat-label>
            <mat-select *ngIf="isPartTime" formControlName="hours">
              <mat-option value="0">0</mat-option>
            </mat-select>
            <mat-select *ngIf="!isPartTime" formControlName="hours">
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="11">11</mat-option>
              <mat-option value="12">12</mat-option>
              <mat-option value="13">13</mat-option>
              <mat-option value="14">14</mat-option>
              <mat-option value="15">15</mat-option>
              <mat-option value="16">16</mat-option>              
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="15" appearance="standard">
            <mat-label>Mins</mat-label>
            <mat-select *ngIf="isPartTime" formControlName="minutes">
              <mat-option value="0">0</mat-option>
            </mat-select>
            <mat-select *ngIf="!isPartTime" formControlName="minutes">
              <mat-option value="1">0</mat-option>
              <mat-option value="2">30</mat-option>
             
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-error *ngIf="ptoForm.hasError('ptoBalance')">Insufficient PTO Balance</mat-error>
        </div>
        <div fxLayout="row">
          <mat-error *ngIf="ptoForm.hasError('payPeriod')">PTO Requests cannot be submitted for the current pay period</mat-error>
        </div>
        <div fxLayout="row">
          <mat-error *ngIf="ptoForm.get('dayTotal').value > 14 && ptoForm.get('isRange').value">WARNING: PTO Day Total exceeds 14</mat-error>
        </div>

        <div fxLayout="row">
          <mat-form-field fxFlex appearance="standard">
            <mat-label>Note</mat-label>
            <label>
              <textarea formControlName="note" matInput></textarea>
            </label>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <button fxFlex mat-flat-button [disabled]="!ptoForm.valid" color="primary">SUBMIT</button>
        </div>
      </div>
    
    </form>

  </div>
</div>
