import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {User} from '../shared/user.interface';
import { PTOHistory } from '../shared/ptoHistory.inteface';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class PTOHistoryService {

  constructor(private api: ApiService) {
}

  getUserData(): Observable<User> {
    return this.api.getRequest(`api/v1/user/Username`);
  }

  getPTOHistory(): Observable<PTOHistory[]> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/GetPTORequest`);
  }  
}
