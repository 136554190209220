import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftBidService {

  constructor(private api: ApiService) { }

  getOpenShifts(employee_id: number, date_from?: string): Observable<any> {
    var apiRequest = date_from === undefined ? 
      `api/v1/ShiftBiddingSelfService/GetOpenShifts/${employee_id}` :
      `api/v1/ShiftBiddingSelfService/GetOpenShifts/${employee_id}/${date_from}`;
    return this.api.getRequest(apiRequest);
  }

  openShiftResponse(shift_id: number, employee_id: number, response: boolean): Observable<string> {
    return this.api.getRequest(`api/v1/ShiftBiddingSelfService/OpenShiftResponse/${shift_id}/${employee_id}/${response}`);
  }

  checkShiftBidProfile(employee_id: number): Observable<boolean> {
    return this.api.getRequest(`api/v1/ShiftBiddingSelfService/CheckEmployeeShiftBidProfile/${employee_id}`);
  }

  getShiftBidProfile(employee_id: number, flag: boolean): Observable<any> {
    return this.api.getRequest(`api/v1/ShiftBiddingSelfService/GetEmployeeShiftBidProfile/${employee_id}/${flag}`);
  }

  updateShiftBidProfile(shift_bid_profile_id: number, mobile_opt_in: boolean): Observable<boolean> {
    return this.api.postRequest(`api/v1/ShiftBiddingSelfService/UpdateEmployeeShiftBidProfile/${shift_bid_profile_id}/${mobile_opt_in}`, {});
  }

  updateShiftBidLocation(shift_bid_location_id: number, selected: boolean): Observable<boolean> {
    return this.api.postRequest(`api/v1/ShiftBiddingSelfService/UpdateEmployeeShiftBidLocation/${shift_bid_location_id}/${selected}`, {});
  }
}
