import {NgModule, ModuleWithProviders, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';

import {MaterialModule} from './material.module';

import {AuthService} from './auth.service';
import {AbstractService} from './abstract.service';
import {DataService} from './data.service';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    MaterialModule,
  ],
  providers: [
    AuthService,
    AbstractService,
    DataService
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
    };
  }

  constructor(@Optional() @SkipSelf() coreModule: CoreModule) {
    if (coreModule) {
      throw new Error('CoreModule already loaded. Import in AppModule only.');
    }
  }
}
