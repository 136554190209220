import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { mergeMap } from "rxjs/operators";
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { InteractionRequiredAuthError } from 'msal';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl = environment.backendUrl;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private msalService: MsalService,
              private http: HttpClient,
              private auth: AuthService) { }

  getRequest(apiRequest: string, options?: any): Observable<any> {
    return from(this.auth.checkToken()).pipe(mergeMap(tokenResponse => {
      sessionStorage.setItem('accessToken',tokenResponse.accessToken);
      // API Request
      var results = options ? this.http.get<any>(this.apiUrl + apiRequest, options) : this.http.get<any>(this.apiUrl + apiRequest);
      return results.pipe(catchError(err => {return this.catchAuthError(err)}));
    }), catchError(err => {
      console.log("Token Error");
      console.log(err);
      if (err instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return this.msalService.instance.acquireTokenRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
    }
    }));
  }

  postRequest(apiRequest: string, data: any): Observable<any> {
    return from(this.auth.checkToken()).pipe(mergeMap(tokenResponse => {
      sessionStorage.setItem('accessToken', tokenResponse.accessToken);
      // API Request
      return this.http.post<any>(this.apiUrl + apiRequest, data).pipe(catchError(err => {return this.catchAuthError(err)}))
    }), catchError(err => {
      console.log("Token Error");
      console.log(err);
      if (err instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return this.msalService.instance.acquireTokenRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
    }
    }));
  }

  private catchAuthError(err: any): any {
    if (err.status == 401 || err.status == 403) {
      console.log("Not Authorized");
      this.auth.removeAuthorizationToken();
    }
    return throwError(err);
  }
}
