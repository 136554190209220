import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, DatePipe, CurrencyPipe} from '@angular/common';
import {LayoutModule} from '@angular/cdk/layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {TokenInterceptor} from './core/token.interceptor';
import {Configuration} from 'msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
9

import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './core/material.module';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ToastrModule} from 'ngx-toastr';

import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {AbstractService} from './core/abstract.service';

import {AppService} from './app.service';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {DemographicsService} from './demographics/demographics.service';
import {DemographicsComponent} from './demographics/demographics.component';
import {ScheduleService} from './schedule/schedule.service';
import {ScheduleComponent} from './schedule/schedule.component';
import {FilterOnDate} from './schedule/filter-on-date';
import {DetailViewComponent} from './detail-view/detail-view.component';
import {MessagesService} from './messages/messages.service';
import {MessagesComponent} from './messages/messages.component';
import {MessageBodyComponent} from './messages/message-body/message-body.component';
import {PtoRequestComponent} from './pto-request/pto-request.component';
import {PtoHistoryComponent} from './pto-history/pto-history.component';
import {MenuButtonComponent} from './menu-button/menu-button.component';
import {environment} from '../environments/environment';
import {NumberToTimePipe} from './shared/numberToTime.pipe';
import {FeatureFlagService} from './featureflag.service';
// @ts-ignore
// eslint-disable-next-line
import { ShiftBidComponent } from './shift-bid/shift-bid.component';
import { ShiftBidService } from './shift-bid/shift-bid.service';
import { ApiService } from './core/api.service';
import { ShiftBitOptionsComponent } from './shift-bit-options/shift-bit-options.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: environment.redirectUrl
      },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.backendUrl, [environment.scopeUri]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.scopeUri]
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ScheduleComponent,
    DemographicsComponent,
    MessagesComponent,
    PtoRequestComponent,
    PtoHistoryComponent,
    MenuButtonComponent,
    DetailViewComponent,
    MessageBodyComponent,
    FilterOnDate,
    NumberToTimePipe,
    ShiftBidComponent,
    ShiftBitOptionsComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    LayoutModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    MsalModule,
    FlexLayoutModule,
    SharedModule,
    ToastrModule.forRoot(),
    MaterialModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    AppComponent,
    ApiService,
    AppService,
    FeatureFlagService,
    MsalGuard,
    MsalService,
    DemographicsService,
    ScheduleService,
    CurrencyPipe,
    ShiftBidService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    MessagesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends AbstractService{ }
