import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduleService } from './schedule.service';
import { NumberToTimePipe } from '../shared/numberToTime.pipe';
import { ScheduleEvent, ScheduleEventDataItem, ScheduleEventType } from '../shared/scheduleEvent.interface';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import * as jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject, zip } from 'rxjs';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { isSameDay, isSameMonth } from 'date-fns';
import { DemographicsService } from '../demographics/demographics.service';

const colors: any = {
  scheduledShift: {
    primary: '#2304d1',
    secondary: '#dce6fc',
  },
  pto: {
    primary: '#cc14cc',
    secondary: '#fdc7ff',
  },
  bonus: {
    primary: '#e09d38',
    secondary: '#f3dab3',
  },
  indirect: {
    primary: '#f08080',
    secondary: '#fad3d3',
  },
  deduction: {
    primary: '#00ffff',
    secondary: '#bdffff',
  },
  callOff: {
    primary: '#01d449',
    secondary: '#abffc8',
  },
  notScheduled: {
    primary: '#757575',
    secondary: '#b0b0b0',
  }
};

const msInDay = 24 * 60 * 60 * 1000;

@Component({
  selector: 'app-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  loading = true;
  scheduleDetails = {} as any;
  schedulePeriod: any[] = [];
  scheduleCalendar: any[] = [];
  date = new Date().toDateString();
  newDate = new Date();
  isSticky = true;
  userName: string;
  userId: string;
  view: CalendarView = CalendarView.Month;
  periodView = '';
  panelOpenState = false;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  viewSelected: string;
  payPeriodStartDate: Date;
  payPeriodEndDate: Date;
  startDate: Date;
  endDate: Date;
  activeDayIsOpen: boolean = false;
  primaryPosition: string;
  weeks_to_return: number = 16;

  refresh: Subject<any> = new Subject();

  constructor(private router: Router,
    private scheduleService: ScheduleService,
    private numberToTimePipe: NumberToTimePipe,
    private demographicService: DemographicsService,
    private currencyPipe: CurrencyPipe,
    private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.scheduleService.getUserData()
      .pipe(
        takeUntil(this.unsubscriber)
      )
      .subscribe(user => {
        this.userName = user.name
        this.userId = user.employee_id;

        zip(
          this.demographicService.getPosition(user.employee_id),
          this.scheduleService.getCurrentPayPeriod(user.employee_id),
          this.scheduleService.getDetailedSchedule(this.userId, this.weeks_to_return)
        ).pipe(
          takeUntil(this.unsubscriber),
          finalize(() => this.loading = false)
        ).subscribe(([positions, payPeriod, detailedSchedule]) => {
          this.primaryPosition = (positions as any).filter(item => item.position_type == 'Primary')[0].position_code;

          this.payPeriodStartDate = new Date(payPeriod.pay_period_start_date);
          this.payPeriodEndDate = new Date(payPeriod.pay_period_end_date);
          this.startDate = new Date(payPeriod.pay_period_start_date);
          this.endDate = new Date(payPeriod.pay_period_end_date);

          console.log("Detailed Schedule: ", detailedSchedule);
          this.scheduleDetails = detailedSchedule;
          this.scheduleSetup();
        });
      });
  }

  weeks2(start, end, weeks, param): any {
    if (param === '+') {
      this.payPeriodStartDate = new Date(start.setDate(this.payPeriodStartDate.getDate() + weeks));
      this.payPeriodEndDate = new Date(end.setDate(this.payPeriodEndDate.getDate() + weeks));
    }
    if (param === '-') {
      this.payPeriodStartDate = new Date(start.setDate(this.payPeriodStartDate.getDate() - weeks));
      this.payPeriodEndDate = new Date(end.setDate(this.payPeriodEndDate.getDate() - weeks));
    }
  }

  scheduleSetup() {
    this.generateSchedulePeriod();
    this.populateScheduleItems();
    this.loading = false
    this.hideLoader();
    document.getElementById('buttonMonth').click();
  }

  generateSchedulePeriod() {

    var currentDay = new Date(this.scheduleDetails.start_date.valueOf());
    var endingDay = new Date(this.scheduleDetails.end_date.valueOf());

    while (currentDay <= endingDay) {
      this.schedulePeriod.push({
        date: new Date(currentDay.valueOf()),
        totalScheduledHrs: 0,
        scheduledShifts: [],
        totalWorkedHrs: 0,
        unscheduledShifts: [],
        totalPTO: 0,
        totalBonus: 0,
        totalDeduction: 0,
        totalIndirect: 0
      });
      currentDay.setDate(currentDay.getDate() + 1);
    }
  }

  schedulePeriodIndexByDate(itemDate: Date): number {
    return Math.round((new Date(itemDate).valueOf() - new Date(this.scheduleDetails.start_date).valueOf()) / msInDay);
  }

  populateScheduleItems() {
    this.scheduleDetails.scheduled_shifts.forEach(item => {
      // Add Schedule Event
      this.scheduleCalendar.push(this.createScheduleEvent(item, ScheduleEventType.ScheduledShift));
      // Add to Pay Period Info 
      var schedulePeriodItem = this.schedulePeriod[this.schedulePeriodIndexByDate(item.schedule_date)];
      schedulePeriodItem.totalScheduledHrs += item.scheduled_hours;

      var scheduledShift = {
        start: item.start_datetime,
        end: item.stop_datetime,
        position: item.position_code,
        clockedShift: null
      }

      if (item.clocked_shift) {
        schedulePeriodItem.totalWorkedHrs += item.clocked_shift.clocked_hours;
        scheduledShift.clockedShift = {
          start: item.clocked_shift.start_datetime,
          end: item.clocked_shift.stop_datetime
        };
      }
      schedulePeriodItem.scheduledShifts.push(scheduledShift);
    });

    this.scheduleDetails.unscheduled_shifts.forEach(item => {
      // Add Schedule Event
      this.scheduleCalendar.push(this.createScheduleEvent(item, ScheduleEventType.NotScheduled));
      // Add to Pay Period Info 
      var schedulePeriodItem = this.schedulePeriod[this.schedulePeriodIndexByDate(item.clockTime)];
      schedulePeriodItem.totalWorkedHrs += item.clocked_hours;
      schedulePeriodItem.unscheduledShifts.push({
        start: item.start_datetime,
        end: item.stop_datetime,
        position: item.r_Position
      });
    });

    this.scheduleDetails.calloffs.forEach(item => {
      // Add Schedule Event
      this.scheduleCalendar.push(this.createScheduleEvent(item, ScheduleEventType.CallOff));
    });

    this.scheduleDetails.ptoShifts.forEach(item => {
      // Add Schedule Event
      this.scheduleCalendar.push(this.createScheduleEvent(item, ScheduleEventType.PTO));
      // Add to Pay Period Info 
      this.schedulePeriod[this.schedulePeriodIndexByDate(item.pto_date)].totalPTO += item.pto_hours;
    });

    this.scheduleDetails.bonusShifts.forEach(item => {
      // Add Schedule Event
      this.scheduleCalendar.push(this.createScheduleEvent(item, ScheduleEventType.Bonus));
      // Add to Pay Period Info 
      this.schedulePeriod[this.schedulePeriodIndexByDate(item.bonus_date)].totalBonus += item.bonus_amt;
    });

    this.scheduleDetails.deductions.forEach(item => {
      // Add Schedule Event
      this.scheduleCalendar.push(this.createScheduleEvent(item, ScheduleEventType.Deduction));
      // Add to Pay Period Info 
      this.schedulePeriod[this.schedulePeriodIndexByDate(item.deduction_date)].totalDeduction += item.deduction_amount;
    });

    this.scheduleDetails.indirects.forEach(item => {
      // Add Schedule Event
      this.scheduleCalendar.push(this.createScheduleEvent(item, ScheduleEventType.Indirect));
      // Add to Pay Period Info 
      this.schedulePeriod[this.schedulePeriodIndexByDate(item.indirect_date)].totalIndirect += item.indirect_hours;
    });
  }

  createScheduleEvent(item: any, type: ScheduleEventType): ScheduleEvent {

    var scheduleEvent = {
      id: this.userId,
      name: this.userName,
      position: this.primaryPosition,
      type: type,
      date: null,
      start: null,
      end: null,
      allDay: false,
      title: '',
      color: '',
      data: []
    }

    switch (type) {
      case ScheduleEventType.ScheduledShift:
        scheduleEvent.date = new Date(item.schedule_date);
        scheduleEvent.start = new Date(item.start_datetime);
        scheduleEvent.end = new Date(item.stop_datetime);
        scheduleEvent.allDay = false;
        scheduleEvent.position = item.position_code;
        scheduleEvent.title = item.position_code + ": " +
          this.datepipe.transform(scheduleEvent.start, 'h:mm a') + " - " +
          this.datepipe.transform(scheduleEvent.end, 'h:mm a');
        scheduleEvent.color = colors.scheduledShift;
        scheduleEvent.data.push({
          detailText: "Scheduled Shift",
          value: this.datepipe.transform(scheduleEvent.start, 'h:mm a') + " - " +
            this.datepipe.transform(scheduleEvent.end, 'h:mm a')
        });
        scheduleEvent.data.push({
          detailText: "Scheduled Hours",
          value: this.numberToTimePipe.transform(item.scheduled_hours, 'd') + " Hours"
        });
        if (item.clocked_shift) {
          scheduleEvent.data.push({
            detailText: "Worked Shift",
            value: this.datepipe.transform(item.clocked_shift.start_datetime, 'h:mm a') + " - " +
              (item.clocked_shift.stop_datetime ? this.datepipe.transform(item.clocked_shift.stop_datetime, 'h:mm a') : "No Punch Out")
          });
          scheduleEvent.data.push({
            detailText: "Worked Hours",
            value: this.numberToTimePipe.transform(item.clocked_shift.clocked_hours, 's') + " Hours"
          });
          if(item.note != "") {
            scheduleEvent.data.push({
              detailText: "Note",
              value: item.note
            });
          }
        }
        break;
      case ScheduleEventType.NotScheduled:
        scheduleEvent.date = new Date(item.clockTime);
        scheduleEvent.start = new Date(item.start_datetime);
        scheduleEvent.end = item.stop_datetime ? new Date(item.stop_datetime) : scheduleEvent.start;
        scheduleEvent.allDay = false;
        scheduleEvent.position = item.r_Position;
        scheduleEvent.title = item.r_Position + ": " +
          this.datepipe.transform(scheduleEvent.start, 'h:mm a') + " - " +
          (item.stop_datetime ? this.datepipe.transform(scheduleEvent.end, 'h:mm a') : "No Punch Out");
        scheduleEvent.color = colors.notScheduled;
        scheduleEvent.data.push({
          detailText: "Unscheduled Shift",
          value: this.datepipe.transform(scheduleEvent.start, 'h:mm a') + " - " +
            (item.stop_datetime ? this.datepipe.transform(scheduleEvent.end, 'h:mm a') : "No Punch Out")
        });
        scheduleEvent.data.push({
          detailText: "Worked Hours",
          value: this.numberToTimePipe.transform(item.clocked_hours, 's') + " Hours"
        });
        break;
      case ScheduleEventType.CallOff:
        scheduleEvent.date = new Date(item.calloff_date);
        scheduleEvent.start = new Date(item.start_datetime);
        scheduleEvent.end = new Date(item.stop_datetime);
        scheduleEvent.allDay = false;
        scheduleEvent.position = item.position_desc;
        scheduleEvent.title = "Call Off: " + item.reason;
        scheduleEvent.color = colors.callOff;
        scheduleEvent.data.push({
          detailText: "Call Off",
          value: item.excused ? "Excused" : "Unexcused"
        });
        scheduleEvent.data.push({
          detailText: "Shift",
          value: this.datepipe.transform(scheduleEvent.start, 'h:mm a') + " - " + this.datepipe.transform(scheduleEvent.end, 'h:mm a')
        })
        scheduleEvent.data.push({
          detailText: "Reason",
          value: item.reason
        });
        if(item.note != "") {
          scheduleEvent.data.push({
            detailText: "Note",
            value: item.note
          });
        }
        break;
      case ScheduleEventType.PTO:
        scheduleEvent.date = new Date(item.pto_date);
        scheduleEvent.start = scheduleEvent.date;
        scheduleEvent.end = scheduleEvent.date;
        scheduleEvent.allDay = true;
        scheduleEvent.title = item.reason_desc + ": " + this.numberToTimePipe.transform(item.pto_hours, 'd') + " Hours";
        scheduleEvent.color = colors.pto;
        scheduleEvent.data.push({
          detailText: "PTO",
          value: this.numberToTimePipe.transform(item.pto_hours, 'd') + " Hours"
        });
        scheduleEvent.data.push({
          detailText: "Reason",
          value: item.reason_desc
        });
        scheduleEvent.data.push({
          detailText: "Source",
          value: item.requested ? "User Requested" : "Scheduler Provided"
        });
        if(item.note != "") {
          scheduleEvent.data.push({
            detailText: "Note",
            value: item.note
          });
        }
        break;
      case ScheduleEventType.Bonus:
        scheduleEvent.date = new Date(item.bonus_date);
        scheduleEvent.start = scheduleEvent.date;
        scheduleEvent.end = scheduleEvent.date;
        scheduleEvent.allDay = true;
        scheduleEvent.title = item.reason_desc + ": " + this.currencyPipe.transform(item.bonus_amt);
        scheduleEvent.color = colors.bonus;
        scheduleEvent.data.push({
          detailText: "Bonus",
          value: this.currencyPipe.transform(item.bonus_amt)
        });
        scheduleEvent.data.push({
          detailText: "Reason",
          value: item.reason_desc
        });
        if(item.note != "") {
          scheduleEvent.data.push({
            detailText: "Note",
            value: item.note
          });
        }
        break;
      case ScheduleEventType.Deduction:
        scheduleEvent.date = new Date(item.deduction_date);
        scheduleEvent.start = scheduleEvent.date;
        scheduleEvent.end = scheduleEvent.date;
        scheduleEvent.allDay = true;
        scheduleEvent.title = item.reason_desc + ": - " + this.currencyPipe.transform(item.deduction_amount);
        scheduleEvent.color = colors.deduction;
        scheduleEvent.data.push({
          detailText: "Deduction",
          value: this.currencyPipe.transform(item.deduction_amount)
        });
        scheduleEvent.data.push({
          detailText: "Reason",
          value: item.reason_desc
        });
        if(item.note != "") {
          scheduleEvent.data.push({
            detailText: "Note",
            value: item.note
          });
        }
        break;
      case ScheduleEventType.Indirect:
        scheduleEvent.date = new Date(item.indirect_date);
        scheduleEvent.start = scheduleEvent.date;
        scheduleEvent.end = scheduleEvent.date;
        scheduleEvent.allDay = true;
        scheduleEvent.title = item.reason_desc + ": " + this.numberToTimePipe.transform(item.indirect_hours, 'd') + " Hours";
        scheduleEvent.color = colors.indirect;
        scheduleEvent.data.push({
          detailText: "Indirect",
          value: this.numberToTimePipe.transform(item.indirect_hours, 'd') + " Hours"
        });
        scheduleEvent.data.push({
          detailText: "Reason",
          value: item.reason_desc
        });
        if(item.note != "") {
          scheduleEvent.data.push({
            detailText: "Note",
            value: item.note
          });
        }
        break;
      default:
        break;
    }

    return scheduleEvent;
  }

  getToday(): void {
    this.payPeriodStartDate = new Date(this.startDate);
    this.payPeriodEndDate = new Date(this.endDate);
  }

  convertToEEEEE(date: Date): string {
    return this.datepipe.transform(date, 'EEEE');
  }

  convertToTime(date: Date): string {
    return this.datepipe.transform(date, 'h:mm a');
  }

  convertToWeek(date: Date): string {
    return this.datepipe.transform(date, 'MM/dd/yyyy');
  }

  filterData(schedulePeriod: any): void {
    //console.log('data', schedulePeriod.filter(item => {return new Date(item.date) >= this.payPeriodStartDate && new Date(item.date) <= this.payPeriodEndDate}));
    return schedulePeriod.filter(item => { return new Date(item.date) >= this.payPeriodStartDate && new Date(item.date) <= this.payPeriodEndDate });
  }

  filterDataMonth(schedulePeriod: any): void {
    var currentYear = this.viewDate.getFullYear();
    var currentMonth = this.viewDate.getMonth();
    var scheduleData = schedulePeriod.filter(item => { return new Date(item.date) >= new Date(currentYear, currentMonth, 1) && new Date(item.date) <= new Date(currentYear, currentMonth, this.getDaysInViewMonth()) });
    //console.log('data', scheduleData);
    return scheduleData;
  }

  hasAvailableData(): boolean {
    return this.periodView === 'period' || this.view === 'month' && (this.filterDataMonth(this.schedulePeriod) as any).length;
  }

  downloadAsPDF(): void {
    if (this.periodView === 'period') {
      this.generatePDFPeriodView();
    } else if (this.view === 'month') {
      this.generatePDFMonthView();
    }
  }

  generatePDFPeriodView(): void {
    var dataForPdf = this.filterData(this.schedulePeriod);
    const doc = new jsPDF.jsPDF('landscape', 'pt', 'A4');
    const columns = [[
      'Week',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Hrs Scheduled'
    ]];

    const data = [];

    for (let k = 0; k < 14; k += 7) {
      let row = [this.convertToWeek(dataForPdf[k].date)];
      let rowSchTotal = 0;
      let rowWrkTotal = 0;
      for (let i = k; i < 7 + k; i++) {
        var cellText = '';
        rowSchTotal += dataForPdf[i].totalScheduledHrs;
        rowWrkTotal += dataForPdf[i].totalWorkedHrs;
        if (dataForPdf[i].scheduledShifts.length > 0 || dataForPdf[i].unscheduledShifts.length > 0) {
          var firstInCell = true;

          if (dataForPdf[i].scheduledShifts.length > 0) {
            dataForPdf[i].scheduledShifts.forEach(shift => {
              cellText += this.formatScheduledCell(shift, firstInCell);
              firstInCell = false;
            });
          }
          if (dataForPdf[i].unscheduledShifts.length > 0) {
            dataForPdf[i].unscheduledShifts.forEach(shift => {
              cellText += this.formatUnscheduledCell(shift, firstInCell);
              firstInCell = false;
            });
          }
        } else {
          cellText += '-\n-\n';
        }

        row.push(cellText);
      }
      row.push('Total\nSchd Hrs ' + this.numberToTimePipe.transform(rowSchTotal, 'd') +
        '\nWrkd Hrs ' + this.numberToTimePipe.transform(rowWrkTotal, 's'));
      data.push(row);
    }

    var pageWidth = doc.internal.pageSize.width;
    console.log("Page Width: ", pageWidth);
    autoTable(doc, {
      head: columns,
      body: data,
      margin: { top: 100 },
      headStyles: { halign: 'center', valign: 'middle' },
      bodyStyles: { fontSize: 8 },
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: { halign: 'center', cellWidth: 44 },
        1: { halign: 'center', cellWidth: 92 },
        2: { halign: 'center', cellWidth: 92 },
        3: { halign: 'center', cellWidth: 92 },
        4: { halign: 'center', cellWidth: 92 },
        5: { halign: 'center', cellWidth: 92 },
        6: { halign: 'center', cellWidth: 92 },
        7: { halign: 'center', cellWidth: 92 },
        8: { halign: 'center', cellWidth: 73.89 }
      },
      didDrawPage: () => {
        const img = new Image()
        img.src = 'assets/images/LHRS-Logo.JPG'
        doc.addImage(img, 'JPEG', 40, 30, 200, 50)
        doc.text(`${this.userName} (${this.userId})\n${this.viewDate.toLocaleString('default', { month: 'long' })} ${this.viewDate.getFullYear()}`, pageWidth - 60, 50, { align: 'right' })
      }
    });

    doc.save('scheduled.pdf');
  }

  generatePDFMonthView(): void {
    let dataForPdf = this.filterDataMonth(this.schedulePeriod); // The data used for the PDF
    var dataStartDay = -1; // The numerical day of the week for the first data element
    var daysInData = (dataForPdf as any).length; // The number of data elements
    var monthStartDay = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1).getDay(); // The numerical day of the week for the beginning of the month
    var daysInMonthView = this.getDaysInViewMonth(); // The number of days in the month
    var rowCount = Math.ceil((daysInMonthView + monthStartDay) / 7); // The number of rows needed for the calendar month

    if (dataForPdf[0]) {
      var dataStartDateFull = new Date(dataForPdf[0].date);
      dataStartDay = dataStartDateFull.getDay();
    }

    if (daysInData == 0) {
      return;
    }

    const doc = new jsPDF.jsPDF('landscape', 'pt', 'A4');
    const columns = [[
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Hours'
    ]];

    const calendar = []; // The calendar array used for the PDF
    var dayCount = 0; // The index for the calendar data

    for (let i = 0; i < rowCount && dayCount < daysInData; i++) {
      var row = [];
      var rowHoursScheduledTotal = 0;
      var rowHoursWorkedTotal = 0;

      var offset = 0;

      if (i == 0) {
        for (let k = 0; k < dataStartDay; k++) {
          row[k] = '';
          offset++;
        }
      }

      for (let k = offset; k < 7 && dayCount < daysInData; k++) {

        var currentDate = new Date(dataForPdf[dayCount].date).getDate();
        var cellText = (this.viewDate.getMonth() + 1) + '/' + (currentDate) + '\n';

        rowHoursScheduledTotal += dataForPdf[dayCount].totalScheduledHrs;
        rowHoursWorkedTotal += dataForPdf[dayCount].totalWorkedHrs;

        if (dataForPdf[dayCount].scheduledShifts.length > 0 || dataForPdf[dayCount].unscheduledShifts.length > 0) {
          var firstInCell = true;

          if (dataForPdf[dayCount].scheduledShifts.length > 0) {
            dataForPdf[dayCount].scheduledShifts.forEach(shift => {
              cellText += this.formatScheduledCell(shift, firstInCell);
              firstInCell = false;
            });
          }
          if (dataForPdf[dayCount].unscheduledShifts.length > 0) {
            dataForPdf[dayCount].unscheduledShifts.forEach(shift => {
              cellText += this.formatUnscheduledCell(shift, firstInCell);
              firstInCell = false;
            });
          }
        } else {
          cellText += '-\n-\n';
        }

        dayCount++;
        row[k] = cellText;
      }

      row[7] = 'Total\nSch ' + this.numberToTimePipe.transform(rowHoursScheduledTotal, 'd') +
        '\nWrkd ' + this.numberToTimePipe.transform(rowHoursWorkedTotal, 's');
      calendar[i] = row;
    }

    var pageHeight = doc.internal.pageSize.height;
    var pageWidth = doc.internal.pageSize.width;

    autoTable(doc, {
      head: columns,
      body: calendar,
      margin: { top: 100 },
      theme: 'grid',
      headStyles: { halign: 'center', valign: 'middle', fillColor: '#5e82f4' },
      bodyStyles: { fontSize: 8 },
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: { halign: 'center', cellWidth: (pageWidth - 100) / 8 + 8 },
        1: { halign: 'center', cellWidth: (pageWidth - 100) / 8 + 8 },
        2: { halign: 'center', cellWidth: (pageWidth - 100) / 8 + 8 },
        3: { halign: 'center', cellWidth: (pageWidth - 100) / 8 + 8 },
        4: { halign: 'center', cellWidth: (pageWidth - 100) / 8 + 8 },
        5: { halign: 'center', cellWidth: (pageWidth - 100) / 8 + 8 },
        6: { halign: 'center', cellWidth: (pageWidth - 100) / 8 + 8 },
        7: { halign: 'center', cellWidth: (pageWidth - 100) / 8 - 36 }
      },
      didDrawPage: (data) => {
        const img = new Image()
        img.src = 'assets/images/LHRS-Logo.JPG'
        doc.addImage(img, 'JPEG', 40, 30, 200, 50)
        doc.text(`${this.userName} (${this.userId})\n${this.viewDate.toLocaleString('default', { month: 'long' })} ${this.viewDate.getFullYear()}`, pageWidth - 60, 50, { align: 'right' })
      }
    });

    doc.save('scheduled.pdf');
  }

  formatScheduledCell(shift: any, firstInCell: boolean): string {
    var cellText = '';
    cellText += firstInCell ? '' : '\n';
    cellText += shift.position + '\n';
    cellText += `Sch ${this.convertToTime(shift.start)}-${this.convertToTime(shift.end)}\n`;
    if (shift.clockedShift) {
      var start = this.convertToTime(shift.clockedShift.start);
      var end = shift.clockedShift.end ? this.convertToTime(shift.clockedShift.end) : 'No Clock Out';
      cellText += `Wrk ${start}-${end}\n`;
    } else {
      cellText += 'Not Worked\n'
    }
    return cellText;
  }

  formatUnscheduledCell(shift: any, firstInCell: boolean): string {
    var cellText = '';
    cellText += firstInCell ? '' : '\n';
    cellText += shift.position + '\n';

    cellText += 'Not Scheduled\n';
    var start = this.convertToTime(shift.start);
    var end = shift.end ? this.convertToTime(shift.end) : 'No Clock Out';
    cellText += `Wrk ${start}-${end}\n`;
    return cellText;
  }

  hideLoader(): void {
    document.getElementById('loading').style.display = 'none';
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.scheduleCalendar = this.scheduleCalendar.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event as ScheduleEvent);
  }

  handleEvent(action: string, event: ScheduleEvent): void {

    console.log("Detail View Event: ", event);
    sessionStorage.setItem('detail_schedule_event', JSON.stringify(event));
    this.router.navigate(['detail-view']);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        if (isSameDay(date, this.viewDate) && this.activeDayIsOpen) {
          this.view = CalendarView.Day;
          this.viewSelected = CalendarView.Day;
        }
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  closeActiveDay(): void {
    this.activeDayIsOpen = false;
  }

  setView(view: CalendarView): void {
    this.periodView = '';
    this.view = view;
    this.viewSelected = view;
  }

  setPeriodView(view: string): void {
    this.periodView = view;
    this.view = CalendarView.Week;
    this.viewSelected = view;
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getDaysInViewMonth(): number {
    return new Date(this.viewDate.getFullYear(), (this.viewDate.getMonth() + 1) % 12, 0).getDate()
  }

  // Future use to fetch data using date range
  getViewBeginEndDates(): any {
    var dateStrings = [];

    if (this.periodView == 'period') {
      dateStrings[0] = (this.payPeriodStartDate.getMonth() + 1) + '/' + this.payPeriodStartDate.getDate() + '/' + this.payPeriodStartDate.getFullYear();
      dateStrings[1] = (this.payPeriodEndDate.getMonth() + 1) + '/' + this.payPeriodEndDate.getDate() + '/' + this.payPeriodEndDate.getFullYear();

    } else if (this.view == CalendarView.Month) {
      dateStrings[0] = (this.viewDate.getMonth() + 1) + '/01/' + this.viewDate.getFullYear();
      dateStrings[1] = (this.viewDate.getMonth() + 1) + '/' + this.getDaysInViewMonth() + '/' + this.viewDate.getFullYear();

    } else if (this.view == CalendarView.Week) {
      dateStrings[0] = (this.viewDate.getMonth() + 1) + '/' + (this.viewDate.getDate() - this.viewDate.getDay()) + '/' + this.viewDate.getFullYear();
      dateStrings[1] = (this.viewDate.getMonth() + 1) + '/' + (this.viewDate.getDate() - this.viewDate.getDay() + 6) + '/' + this.viewDate.getFullYear();
    }

    return dateStrings;
  }
}
