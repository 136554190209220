import { Pipe, PipeTransform } from '@angular/core'
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
@Pipe({name: 'numberToTime'})
export class NumberToTimePipe implements PipeTransform {
    transform(value: any, format = 'd'): string {
       
        var time = Number(value);
        var factor = format == 's' ? 100 : 60;
        
        var hours = Math.floor(time);
        var minutes = Math.round((time - hours) * factor);
        
        return minutes < 10 ? (hours + ":0" + minutes) : (hours + ":" + minutes)
    }
}