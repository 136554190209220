import { Component, OnInit } from '@angular/core';
import { Subject, zip } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { DemographicsService } from '../demographics/demographics.service';
import { ShiftBidService } from '../shift-bid/shift-bid.service';
import { FeatureFlagService } from '../featureflag.service';

@Component({
  selector: 'app-shift-bit-options',
  templateUrl: './shift-bit-options.component.html',
  styleUrls: ['./shift-bit-options.component.scss']
})
export class ShiftBitOptionsComponent implements OnInit {

  private unsubscriber: Subject<void> = new Subject<void>();

  mobileChecked = false;
  loading: boolean = true;
  employee_id: string;
  profile_id: number;
  shift_bidding_flag=false;
  primary_location_restriction_flag=true;
  mobile_phone: string;
  allLocations = [];
  addedLocations = [];
  availableLocations = [];

  constructor(private demographicsService: DemographicsService,
              private shiftBidService: ShiftBidService,
              private ffservice: FeatureFlagService) { }

  ngOnInit(): void {
    this.demographicsService.getUserData().pipe(
      takeUntil(this.unsubscriber)
    )
    .subscribe(user => {
      this.employee_id = user.employee_id;
      this.setupProfile();
    });
  }

  async setupProfile() {
    await this.getFeatureFlags();

    zip(
      this.shiftBidService.getShiftBidProfile(Number(this.employee_id), this.shift_bidding_flag),
      this.demographicsService.getProfilePhone(this.employee_id))
    .pipe(
      takeUntil(this.unsubscriber),
      finalize(() => this.loading = false))
    .subscribe(([profile, phones]) => {
      console.log("Profile: ", profile);
      console.log("Phones: ", phones);
      var mobile = phones.find(phone => phone.phone_type == "Mobile");
      this.mobile_phone = mobile ? this.formatPhoneNumber(mobile.phone_number) : null;
      this.profile_id = profile.id;
      this.mobileChecked = profile.mobile_opt_in;
      this.allLocations = this.primary_location_restriction_flag ? 
        profile.shiftBidLocations.filter(location => location.primary_location) : 
        profile.shiftBidLocations;
      this.filterLocations();
    });
  }

  async getFeatureFlags() {
    if(await this.ffservice.getFlagValue('self-service-shift-bidding')){
      this.shift_bidding_flag=true;
      console.log('################## showShiftBidding=true');
    }
    else{
      this.shift_bidding_flag=false;
      console.log('################## showShiftBidding=false');
    }

    if(await this.ffservice.getFlagValue('shift-bidding-primary-location-restriction')){
      this.primary_location_restriction_flag=true;
      console.log('################## primaryLocationRestriction=true');
    }
    else{
      this.primary_location_restriction_flag=false;
      console.log('################## primaryLocationRestriction=false');
    }
  }

  mobileOptIn(): void {
    this.loading = true;
    this.shiftBidService.updateShiftBidProfile(this.profile_id, this.mobileChecked).pipe(
      takeUntil(this.unsubscriber),
      finalize(() => this.loading = false)
    ).subscribe(result => {
      if(result) {
        console.log("Opt In Success: ", this.mobileChecked);
      } else {
        this.mobileChecked = !this.mobileChecked
        console.log("Profile Update Failed")
      }
    });
  }

  formatPhoneNumber(phoneNumber: string): string {
    return "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6);
  }

  remove(location: any): void {
    this.loading = true;
    this.shiftBidService.updateShiftBidLocation(location.id, false).pipe(
      takeUntil(this.unsubscriber),
      finalize(() => this.loading = false)
    ).subscribe(result => {
      location.selected = false;
      this.filterLocations();
      console.log("Remove Location Success: ", location.location_id);
      console.log("Number of Responses Affected: ", result);
    });
  }

  accept(location: any): void {
    this.loading = true;
    this.shiftBidService.updateShiftBidLocation(location.id, true).pipe(
      takeUntil(this.unsubscriber),
      finalize(() => this.loading = false)
    ).subscribe(result => {
      location.selected = true;
      this.filterLocations();
      console.log("Accept Location Success: ", location.location_id);
      console.log("Number of Responses Affected: ", result);
    });
  }

  filterLocations():void {
    this.addedLocations = [];
    this.availableLocations = [];
    this.allLocations.forEach(loc => { loc.selected ? this.addedLocations.push(loc) : this.availableLocations.push(loc)});
  }
}
