import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractService} from '../core/abstract.service';
import {Subject} from 'rxjs';
import {MediaMatcher} from '@angular/cdk/layout';
import {PTOHistoryService} from './pto-history.service';
import {DataService} from '../core/data.service';
import {Router} from '@angular/router';
import {finalize, takeUntil} from 'rxjs/operators';
import { PTOHistory } from '../shared/ptoHistory.inteface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pto-history',
  templateUrl: './pto-history.component.html',
  styleUrls: ['./pto-history.component.scss']
})
export class PtoHistoryComponent extends AbstractService implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();
  matcher: MediaQueryList;
  loading = false;
  ptoHistory: PTOHistory[] = [];
  dataSource: any[] = [];

  constructor(private router: Router,
    public mediaMatcher: MediaMatcher,
    private PHS: PTOHistoryService,
    private dataService: DataService,
    private datePipe: DatePipe) {
    super();
  }

  ngOnInit(): void {
    this.matcher = this.mediaMatcher.matchMedia('(max-width: 959px)');

    this.loading = true;
      setTimeout(() => {
          this.PHS.getPTOHistory()
             .pipe(
                takeUntil(this.unsubscriber),
                finalize(() => this.loading = false)
              )
              .subscribe((d) => {
                console.log(d);
                this.ptoHistory = d;
                this.setupDataSource();
              });
              console.log("PTO History: ", this.ptoHistory);
    }, 2000)
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  setupDataSource() {
    this.ptoHistory.forEach(request => {
      if(!request.request_status_desc || request.request_status_desc == "PTO Request Pending") {
        this.dataSource.push({
          pto_request_date: request.pto_request_date,
          status_desc: "PTO Request Pending",
          status_update: "Submitted: " + this.datePipe.transform(request.updateDateTime, 'MMM d, y, h:mm a'),
          note: request.note_sched,
          hours: request.pto_request_hours,
          css_class: "status pending"
        });
      } else {
        this.dataSource.push({
          pto_request_date: request.pto_request_date,
          status_desc: request.request_status_desc,
          status_update: "Updated By: " + request.username + " - " + this.datePipe.transform(request.updateDateTime, 'MMM d, y, h:mm a'),
          note: request.note_sched,
          hours: request.pto_request_hours,
          css_class: request.request_status_desc == "PTO Request Approved" ? "status approved" : "status denied"
        });
      }
    });
  }
}
