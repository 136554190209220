import {Component, OnDestroy, OnInit} from '@angular/core';
import {DemographicsService} from './demographics.service';
import {Subject, zip} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {MsalService} from '@azure/msal-angular';
import {AuthService} from '../core/auth.service';
import {AppComponent} from '../app.component';
import { ProfilePhone } from '../shared/profile.interface';


@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss']
})
export class DemographicsComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  loading = false;
  login_process_completed = false;
  profile = {} as any;
  profileEmail = {} as any;
  profilePhone: ProfilePhone[] = [];
  profilePosition = {} as any;
  
  constructor(private demographicsService: DemographicsService,
              private msalService: MsalService,
              private auth: AuthService,
              private app: AppComponent) {
  }

  ngOnInit(): void {
    console.log('demographics ngOnInit');
    this.loading = true;

    this.demographicsService.getUserData()
      .pipe(
        takeUntil(this.unsubscriber)
      )
      .subscribe(user => {
        zip(
          this.demographicsService.getProfile(user.employee_id),
          this.demographicsService.getProfileEmail(user.employee_id),
          this.demographicsService.getProfilePhone(user.employee_id),
          this.demographicsService.getPosition(user.employee_id))
          .pipe(
            takeUntil(this.unsubscriber),
            finalize(() => this.loading = false)
          )
          .subscribe(([profile, email, phones, position]) => {
            this.profile = profile;
            this.profileEmail = email;
            this.profilePhone = phones;
            this.profilePosition = position;
          });
      });
  }

  checkloginstatus(){
   if(!sessionStorage.getItem('accessToken'))
   {
     console.log('##############Token Empty');
     this.auth.getToken();
   }
  }

  logout(): void {
    this.msalService.logout();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  } 
}



