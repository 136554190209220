import { Injectable,Inject } from '@angular/core';
import { Location } from "@angular/common";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus,BrowserUtils, PopupRequest, RedirectRequest, EventMessage, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { filter,takeUntil } from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthService {
  export_token: string;
  isIframe = false;
  loginDisplay = false;
  accessTokenRequest = {
    scopes: [`${environment.scopeUri}`]
  };
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private location: Location) {

  }
  login(): Observable<any>{
    const currentPath = this.location.path();
    this.isIframe = BrowserUtils.isInIframe() && !window.opener && currentPath.indexOf("logout") < 0;
    return this.msalBroadcastService.inProgress$;
  }
  getToken(){
    this.authService.instance.acquireTokenSilent({...this.msalGuardConfig.authRequest} as RedirectRequest).then(tokenResponse => {
        sessionStorage.setItem('accessToken',tokenResponse.accessToken);
      }).catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return this.authService.instance.acquireTokenRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
          }
      });
  }

  checkToken(): Promise<AuthenticationResult> {
    console.log("Checking Token");
    return this.authService.instance.acquireTokenSilent({...this.msalGuardConfig.authRequest} as RedirectRequest);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    console.log("Checking Token2222");
    console.log(activeAccount);

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
    this.removeAuthorizationToken();
  }

  setAutorizationToken(){
    this.export_token = sessionStorage.getItem('accessToken');
    console.log("token " + this.export_token);
  }

  removeAuthorizationToken(){
    this.export_token = null;
    sessionStorage.setItem('accessToken', null);
  }
}
