<div fxFlex fxLayout="column" class="content-wrap">
  <!-- Toolbar -->
  <mat-toolbar>
    <button mat-button class="back-button" color="primary" routerLink="./../shift-bid">
      <mat-icon class="icon-chevron-left"></mat-icon>
      <span>Shift Bidding</span>
    </button>

    <div fxFlex>
      <h2 class="title">Shift Bid Options</h2>
    </div>
  </mat-toolbar>

  <!-- Content -->
  <mat-spinner strokeWidth="3" [diameter]="50" *ngIf="loading"></mat-spinner>
  <div *ngIf="!loading" class="content">
    <mat-checkbox class="mobile-checkbox" [(ngModel)]="mobileChecked" [disabled]="!mobile_phone" (change)="mobileOptIn()">Accept Mobile
      Alerts</mat-checkbox>
    <div *ngIf="mobile_phone">
      <p>By opting in, you consent to receive text messages to <b class="mobile-number">{{mobile_phone}}</b></p>
      <p>Message and data rates may apply.</p>
    </div>
    <div *ngIf="!mobile_phone">
      <p>There is no existing mobile phone assigned to your account.</p>
      <p>Please contact your supervisor for help.</p>
    </div>
    <mat-divider class="divider"></mat-divider>
  
    <h4 class="options-header">Accepted Locations</h4>
    
    <mat-accordion  *ngIf="addedLocations.length > 0" class="location-select">
      <mat-expansion-panel class="mat-elevation-z0" *ngFor="let loc of addedLocations">
        <mat-expansion-panel-header class="location-box">
          <mat-panel-title>
            {{loc.location_short_name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="loc.hasAddress">
          <p>{{loc.address_1}}</p>
          <p *ngIf="loc.address_2 != ''">{{loc.address_2}}</p>
          <p>{{loc.city}}, {{loc.state}} {{loc.zip}}</p>
        </div>
        <div *ngIf="!loc.hasAddress">
          <p>Address Unavailable</p>
        </div>
        <button mat-raised-button color="accent" (click)="remove(loc)">
          Remove
        </button>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="addedLocations.length == 0" class="location-select no_content">
      <p>No Accepted Locations</p>
    </div>
  
    <mat-divider class="divider"></mat-divider>
    
    <h4 class="options-header">Available Locations</h4>
  
    <mat-accordion *ngIf="availableLocations.length > 0">
      <mat-expansion-panel class="mat-elevation-z0" *ngFor="let loc of availableLocations">
        <mat-expansion-panel-header class="location-box">
          <mat-panel-title>
            {{loc.location_short_name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="loc.hasAddress">
          <p>{{loc.address_1}}</p>
          <p *ngIf="loc.address_2 != ''">{{loc.address_2}}</p>
          <p>{{loc.city}}, {{loc.state}} {{loc.zip}}</p>
        </div>
        <div *ngIf="!loc.hasAddress">
          <p>Address Unavailable</p>
        </div>
        <button mat-raised-button color="primary" (click)="accept(loc)">
          Accept
        </button>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="availableLocations.length == 0" class="no_content">
      <p>All Available Locations Accepted</p>
    </div>
  </div>
</div>