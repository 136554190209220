<mat-spinner strokeWidth="3" [diameter]="50" *ngIf="loading"></mat-spinner>
<div fxFlex fxLayout="column" class="content-wrap" *ngIf="messages && !loading">
  <!-- Toolbar -->
  <mat-toolbar>
    <app-menu-button fxLayout="column" class="menu-button"></app-menu-button>
    <div fxFlex>
      <h2 class="title">
        Messages
        <span *ngIf="totalNoRead && totalNoRead !== 0" class="new-message-count">{{totalNoRead}}</span>
      </h2>
    </div>
  </mat-toolbar>

  <!-- Content -->
  <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="content">
    <div fxFlex fxLayout.gt-sm="row" class="message">
      <div fxFlex class="message-quick-view">
        <mat-nav-list>
          <mat-list-item *ngFor="let message of messages" (click)="saveBody(message)">
            <div fxFlex fxLayout="column"
                 [ngClass]="message.message_read ? 'message-quick-view-item' : 'message-quick-view-item new'">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div class="message-sender">{{message.from.name}}</div>
                <div class="message-date">{{message.date | date: 'mediumDate'}}</div>
              </div>
              <div class="message-subject">{{message.subject}}</div>
              <div class="message-text">{{message.message}}</div>
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>

      <div fxFlex fxLayout.gt-sm="column" class="message-body" fxHide fxShow.gt-sm>
        <app-message-body fxFlex *ngIf="!matcher.matches"></app-message-body>
      </div>
    </div>
  </div>
</div>
