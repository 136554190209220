export const environment = {
  production: false,
  envName: 'dev',
  baseUrl: 'https://sample.libertytester.com/',
  redirectUrl: 'https://sample.libertytester.com/',
  backendUrl: 'https://app-api-genesis-selfservice-pipeline-eastus-dev-01.azurewebsites.net/ExtAPI/',
  scopeUri: 'f901eb5f-c1eb-42b4-b7da-ccdd161e49aa/user_impersonation',
  tenantId: '9ccd3796-bf60-4eb4-8f53-6b78ff39be60',
  clientId: 'f901eb5f-c1eb-42b4-b7da-ccdd161e49aa',
  authority: 'https://login.microsoftonline.com/9ccd3796-bf60-4eb4-8f53-6b78ff39be60/',
  flagsmithenvID: "djATCSY5tvaWGq8GfzhF7A"
};
