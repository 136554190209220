export interface ScheduleEvent {
    start: Date;
    end: Date;
    allDay: boolean;
    title: string;
    color: any;
    id: string;
    date: Date;
    name: string;
    position: string;
    type: ScheduleEventType;
    data: ScheduleEventDataItem[];
}

export interface ScheduleEventDataItem {
    detailText: string;
    value: any;
} 

export enum ScheduleEventType {
    ScheduledShift,
    PTO,
    Bonus,
    Indirect,
    Deduction,
    CallOff,
    NotScheduled
}