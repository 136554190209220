import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MsalGuard} from '@azure/msal-angular';

import {NotFoundComponent} from './shared/not-found/not-found.component';
import {ScheduleComponent} from './schedule/schedule.component';
import {DemographicsComponent} from './demographics/demographics.component';
import {MessagesComponent} from './messages/messages.component';
import {PtoRequestComponent} from './pto-request/pto-request.component';
import {PtoHistoryComponent} from './pto-history/pto-history.component';
import {DetailViewComponent} from './detail-view/detail-view.component';
import {MessageBodyComponent} from './messages/message-body/message-body.component';
import { ShiftBidComponent } from './shift-bid/shift-bid.component';
import { ShiftBitOptionsComponent } from './shift-bit-options/shift-bit-options.component';

export const routes: Routes = [
  {
    path: 'demographics',
    component: DemographicsComponent,
    canActivate : [MsalGuard]
  },
  {
    path: 'schedule',
    component: ScheduleComponent,
    canActivate : [MsalGuard],
    children: [
      {
        path: 'detail/:id',
        component: DetailViewComponent,
      },
    ]
  },
  {
    path: 'detail-view',
    component: DetailViewComponent,
    canActivate : [MsalGuard]
  },
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate : [MsalGuard],
  },
  {
    path: 'messages/detail',
    component: MessageBodyComponent,
    canActivate : [MsalGuard]
  },
  {
    path: 'pto-request',
    component: PtoRequestComponent,
    canActivate : [MsalGuard]
  },
  {
    path: 'pto-history',
    component: PtoHistoryComponent,
    canActivate : [MsalGuard]
  },
  {
    path: 'shift-bid',
    component: ShiftBidComponent,
    canActivate : [MsalGuard]
  },
  {
    path: 'shift-bid-options',
    component: ShiftBitOptionsComponent,
    canActivate : [MsalGuard]
  },
  {
    path: '',
    redirectTo: 'demographics',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule],
  providers: [MsalGuard]
})
export class AppRoutingModule {
}
