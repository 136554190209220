<mat-spinner strokeWidth="3" [diameter]="50" *ngIf="loading"></mat-spinner>
<div fxFlex fxLayout="column" class="content-wrap">
  <!-- Toolbar -->
  <mat-toolbar>
    <button mat-button class="back-button" color="primary" routerLink="./../pto-request">
      <mat-icon class="icon-chevron-left"></mat-icon>
      <span fxHide.gt-sm>Request</span>
    </button>

    <div fxFlex>
      <h2 class="title">PTO History</h2>
    </div>
  </mat-toolbar>

  <!-- Content -->
  <div fxFlex class="content">
    <div *ngIf="dataSource.length > 0 && !loading">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let d of dataSource">
          <mat-expansion-panel-header>
            <mat-panel-title fxFlex="30" fxLayoutAlign="start center">
              {{d.pto_request_date| date: 'MMM d, y'}}
            </mat-panel-title>
            <mat-panel-description fxFlex="70">
              <div [ngClass]="d.css_class" fxFlex="75" fxLayoutAlign="start center">{{d.status_desc}}</div>
              <div fxFlex="25" fxLayoutAlign="end center">
                <span class="balance-value">{{d.hours}}</span>
                <span class="label-hrs">hrs</span>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p *ngIf="d.note">Note: {{d.note}}</p>
          <p>{{d.status_update}}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div *ngIf="dataSource.length == 0 && !loading">
      No PTO Request History
    </div>
  </div>
</div>