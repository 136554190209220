import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let jwt = sessionStorage.getItem('accessToken');
    if (!!jwt) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`,
          Accept: 'application/json',
        }
      });
    }
    return next.handle(req);
  }
}
