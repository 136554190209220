import {Injectable} from '@angular/core';
import {User} from '../shared/user.interface';
import {Observable } from 'rxjs';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private api: ApiService) {
}

  getUserData(): Observable<User> {
    return this.api.getRequest(`api/v1/user/Username`);
  }

  getDetailedSchedule(id: string, weeks_to_return: number): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/DetailedSchedule/${id}/${weeks_to_return}`);
  }

  getSchedule(id: string, weeks_to_return: number): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/Schedule/${id}?weeks_to_return=${weeks_to_return}`);
  }

  getScheduleRangeDate(id: string, begin_date: string, end_date: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/ScheduleByRange?employee_id=${id}&begin_date=${begin_date}&end_date=${end_date}`);
  }

  getScheduleShift(id: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/ScheduledShift/${id}`);
  }

  getClockedShift(id: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/ClockedShift/${id}`);
  }

  getPto(id: string, date: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/PTOShift/${id}?shift_date=${date}`);
  }

  getDeduction(id: string, date: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/DeductionShift/${id}?shift_date=${date}`);
  }

  getBonus(id: string, date: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/BonusShift/${id}?shift_date=${date}`);
  }

  getInDirect(id: string, date: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/IndirectShift/${id}?shift_date=${date}`);
  }

  getPayPeriod(id: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/PayPeriods/${id}`);
  }
  
  getCurrentPayPeriod(id: string): Observable<any> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/CurrentPayPeriod/${id}`);
  }
}
