import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, zip } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ShiftBidService } from './shift-bid.service';
import { DemographicsService } from '../demographics/demographics.service';
import { DatePipe } from '@angular/common';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-shift-bid',
  templateUrl: './shift-bid.component.html',
  styleUrls: ['./shift-bid.component.scss']
})
export class ShiftBidComponent implements OnInit {
  
  private unsubscriber: Subject<void> = new Subject<void>();
  loading: boolean = true;
  selected = new UntypedFormControl(0);

  employee_id: number;
  openShiftsAll = [] as any;
  openShiftsCurrent = [] as any;
  pendingShifts = [] as any;
  shiftHistory = [] as any;
  declinedShifts = [] as any;
  currentDate: Date = new Date();

  filterResults: boolean = false;
  profileSetupComplete: boolean = false;

  constructor(private router: Router,
    private demographicsService: DemographicsService,
    private shiftBidService: ShiftBidService,
    private datePipe: DatePipe,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.loading = true;
    this.currentDate = new Date(this.datePipe.transform(this.currentDate, 'shortDate'));

    this.demographicsService.getUserData().pipe(
      takeUntil(this.unsubscriber)
    )
    .subscribe(user => {
      this.employee_id = Number(user.employee_id);
      this.shiftBidService.checkShiftBidProfile(this.employee_id).pipe(
        takeUntil(this.unsubscriber)
      )
      .subscribe(complete => {
        this.profileSetupComplete = complete;
        if(this.profileSetupComplete) {
          this.getOpenShifts();
        } else {
          this.loading = false;
        }
      });
    });
  }

  getOpenShifts() {
    console.log("Date From: ", this.getDateFrom());
    this.shiftBidService.getOpenShifts(this.employee_id, this.getDateFrom()).pipe(
      takeUntil(this.unsubscriber),
      finalize(() => this.loading = false)
    )
    .subscribe(openShifts => {
      this.clearShiftLists();
      this.openShiftsAll = openShifts;
      this.sortOpenShifts();
    });
  }

  clearShiftLists() {
    this.openShiftsAll = [] as any;
    this.openShiftsCurrent = [] as any;
    this.pendingShifts = [] as any;
    this.shiftHistory = [] as any;
    this.declinedShifts = [] as any;
  }

  requestShift(shift: any) {
    console.log("Requesting Shift:", shift.id);
    this.openShiftResponse(shift.id, true);
  }

  declineShift(shift: any) {
    console.log("Declining Shift:", shift.id);
    this.openShiftResponse(shift.id, false);
  }

  openShiftResponse(shift_id: number, response: boolean) {
    this.loading = true;
    this.shiftBidService.openShiftResponse(shift_id, this.employee_id, response).pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(message => {
      console.log(message);
      this.getOpenShifts();
      this.displayMessage(message, response);
    });
  }

  displayMessage(message: string, response: boolean) {
    var snackbarMessage = '';

    if(message.includes("ERROR")) {

      if(response) {
        snackbarMessage = "Shift Not Requested: " + message.replace("ERROR: ", "");
      } else {
        snackbarMessage = "Shift Not Declined: " + message.replace("ERROR: ", "");
      }
    } else {
      if(response) {
        snackbarMessage = "Shift Requested";
      } else {
        snackbarMessage = "Shift Declined";
      }
    }

    this.snackbar.open(snackbarMessage, "Dismiss")
  }

  options(): void {
    this.router.navigate(['shift-bid-options']);
  }

  sortOpenShifts() {
    this.openShiftsAll.sort((a,b) => a.date > b.date ? 1 : -1);  
    console.log("Open Shifts: ", this.openShiftsAll);

    this.openShiftsAll.forEach(shift => {
      if(new Date(shift.date) < this.currentDate || shift.status != "New") {
        this.shiftHistory.push(shift);
      } else if (shift.employee_response == "Declined to Cover") {
        this.declinedShifts.push(shift);
      } else if(shift.employee_response == "No Response" || shift.employee_response == "Schedule Conflict") {
        this.openShiftsCurrent.push(shift);
      } else if(shift.employee_response == "Offered to Cover"){
        this.pendingShifts.push(shift);
      }
    });
    
    this.shiftHistory = this.shiftHistory.reverse();
  }

  getDateFrom(): string {
    var date_from = new Date();
    date_from.setMonth(date_from.getMonth()-1)
    return date_from.getFullYear() + '-' + (date_from.getMonth()+1) + '-' + date_from.getDate();
  }
}
