import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDate'
})
@Injectable()
export class FilterOnDate implements PipeTransform {
  transform(items: any[], f1: Date, f2: Date): any[] {
    if (!items) {
      return [];
    }
    if (f1 && f2) {
      return items.filter(item => {return new Date(item.date) >= f1 && new Date(item.date) <= f2});
    } else {
      return items;
    }
  }
}
