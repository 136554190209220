<mat-drawer-container hasBackdrop="false">
  <mat-drawer #drawer mode="over" [opened]="drawer.opened || mobileMode" disableClose="mobileMode">
    <div fxHide fxShow.gt-sm class="title">
      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon class="icon-menu"></mat-icon>
      </button>
      <div class="logoImg">
        <img src="{{baseUrl}}assets/images/genesis-logo.svg" alt=""/>
      </div>
    </div>
    <mat-nav-list>
      <mat-list-item class="parent" routerLink="./demographics" routerLinkActive="active" (click)="drawerClose()">
        <mat-icon [ngClass]="router.url.startsWith('/demographics') ? 'icon-demographics-active' : 'icon-demographics-inactive'" routerLinkActive="active"></mat-icon>
        <span class="item-text" routerLinkActive="active">Demographics</span>
      </mat-list-item>
      <mat-list-item class="parent" routerLink="./schedule" routerLinkActive="active" (click)="drawerClose()">
        <mat-icon [ngClass]="router.url.startsWith('/schedule') ? 'icon-schedule-active' : 'icon-schedule-inactive'" routerLinkActive="active"></mat-icon>
        <span class="item-text" routerLinkActive="active">Schedule</span>
      </mat-list-item>
      <mat-list-item class="parent new-messages" routerLink="./messages" routerLinkActive="active" (click)="drawerClose()">
        <mat-icon [ngClass]="router.url.startsWith('/messages') ? 'icon-messages-active' : 'icon-messages-inactive'" routerLinkActive="active"></mat-icon>
        <span class="item-text" routerLinkActive="active">Messages</span>
      </mat-list-item>
      <mat-list-item *ngIf="showPTORequest" class="parent" routerLink="./pto-request" routerLinkActive="active" (click)="drawerClose()">
        <mat-icon [ngClass]="router.url.startsWith('/pto-request') ? 'icon-pto-active' : 'icon-pto-inactive'" routerLinkActive="active"></mat-icon>
        <span class="item-text" routerLinkActive="active">PTO Request</span>
      </mat-list-item>
      <mat-list-item *ngIf="showShiftBidding" class="parent" routerLink="./shift-bid" routerLinkActive="active" (click)="drawerClose()">
        <mat-icon [ngClass]="router.url.startsWith('/shift-bid') ? 'icon-shift-bid-active' : 'icon-shift-bid-inactive'" routerLinkActive="active"></mat-icon>
        <span class="item-text" routerLinkActive="active">Shift Bidding</span>
      </mat-list-item>
      <mat-list-item class="parent" (click)="logout()" fxHide fxShow.gt-sm>
        <mat-icon class="icon-sign-out" routerLinkActive="active"></mat-icon>
        <span class="item-text" routerLinkActive="active">Logout</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content fxFlex fxLayout="column">
      <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
