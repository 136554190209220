import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDrawer} from '@angular/material/sidenav';
import {AbstractService} from './core/abstract.service';
import {Subscription,Subject} from 'rxjs';
import { AuthService } from './core/auth.service';
import {environment} from '../environments/environment';
import { FeatureFlagService } from './featureflag.service';
import { DemographicsService } from './demographics/demographics.service';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent extends AbstractService implements OnInit, OnDestroy {
  @ViewChild('drawer') public drawer: MatDrawer;

  private subscription: Subscription;  

  showPTORequest=false;
  showShiftBidding=false;
  profile = {} as any;
  location;
  mobileMode;

  private readonly _destroying$ = new Subject<void>();
  
  constructor(
              public router: Router,
              private _authService: AuthService,
              private ffservice: FeatureFlagService,
              private demoservice: DemographicsService,
              private breakpointObserver: BreakpointObserver
              ) {
    super();
    this.breakpointObserver.observe([
      "(max-width: 959px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.mobileMode = true
      } else {
        this.mobileMode = false;
      }
    });
  }

   ngOnInit(): void {
    this._authService.login().pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this._authService.setLoginDisplay();
      this._authService.checkAndSetActiveAccount();

      this.setPTORequestFlag();
      this.setShiftBiddingFlag();

      this.demoservice.getUserData().subscribe(u => {
        this.demoservice.getProfile(u.employee_id).subscribe(p => {
          this.demoservice.getFeatureFlag(p.location).subscribe(f => {
            if (f && this.showShiftBidding) {
              this.showShiftBidding = true;
            } else {
              this.showShiftBidding = false;
            }
          });
        });
      });
    })
    
  }

  ngAfterViewInit(): void{
    this._authService.getToken();
  }

   logout(popup?: boolean) {
     this._authService.logout();
   }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async setShiftBiddingFlag() {
    if(await this.ffservice.getFlagValue('self-service-shift-bidding')){
      this.showShiftBidding=true;
      console.log('################## showShiftBidding=true');
    }
    else{
      this.showShiftBidding=false;
      console.log('################## showShiftBidding=false');
    }
  }

  async setPTORequestFlag() {
    if(await this.ffservice.getFlagValue('self-service-pto-request')){
      this.showPTORequest=true;
      console.log('################## showPTORequest=true');
    }
    else{
      this.showPTORequest=false;
      console.log('################## showPTORequest=false');
    }
  }

  drawerToggle(): void {
    this.drawer.toggle();
  }

  drawerClose(): void {
    if(!this.mobileMode) {
      this.drawer.close();
    }
  }
 }
