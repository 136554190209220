import {Component, OnDestroy, OnInit} from '@angular/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import { Subject, throwError, zip } from 'rxjs';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PtoRequestService } from './pto-request.service';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { DemographicsService } from '../demographics/demographics.service';
import { AuthService } from '../core/auth.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import * as  flagsmith from 'flagsmith-nodejs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
    dbInput: 'YYYY-MM-DD',
    payPeriod: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-pto-request',
  templateUrl: './pto-request.component.html',
  styleUrls: ['./pto-request.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PtoRequestComponent implements OnInit, OnDestroy {

  private unsubscriber: Subject<void> = new Subject<void>();
  ptoForm: UntypedFormGroup;
  loading = false;
  user;
  userProfile;
  isPartTime = false;
  payPeriod = {
    pay_period_start_date: '',
    pay_period_end_date: '',
    isSet: false
  };
  message;
  showPTORequestHistory=false;

  weekendsDatesFilter = (d: Date): boolean => {
    const day = (new Date(d)).getDay();
    return !this.ptoForm.value.excludeWeekend || day !== 0 && day !== 6;
  };

  constructor(public fb: UntypedFormBuilder, 
              public ptoService: PtoRequestService,
              private auth: AuthService,
              public demoService: DemographicsService,
              private router: Router,
              private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.loading = true;
    this.demoService.getUserData().pipe(
      takeUntil(this.unsubscriber),
      catchError(err => {
        console.log('ERROR: ', err);
        return throwError(err);
      })
    ).subscribe(user => {
      this.user = user;
      zip(
        this.demoService.getProfile(user.employee_id),
        this.ptoService.getCurrentPayPeriod(user.employee_id)
      ).pipe(
        takeUntil(this.unsubscriber),
        catchError(err => { return throwError(err); }),
        finalize(() => this.loading = false)
      ).subscribe(([profile, pay_period]) => {
        this.userProfile = profile;
        this.isPartTime = (profile.status_description == 'Active Part Time Hourly' || profile.status_description == 'Active Part Time Salaried');
        console.log("PTO Balance RAW: ", this.userProfile.ptoBalance);
        this.payPeriod.pay_period_start_date = moment(pay_period.pay_period_start_date).format(MY_FORMATS.parse.payPeriod);
        this.payPeriod.pay_period_end_date = moment(pay_period.pay_period_end_date).format(MY_FORMATS.parse.payPeriod);
        this.payPeriod.isSet = true;
        this.setupPTOForm();
      })
    },(e) => {
      console.log('Error: ', e)
    });
    this.initializeFlagSmith();
  }
  initializeFlagSmith(){
    flagsmith.init({
      environmentID: `${environment.flagsmithenvID}`,
      });
      flagsmith.hasFeature("pto-request-show-history")
      .then((featureEnabled) => {
          if (featureEnabled) {
            this.showPTORequestHistory=true;
            console.log("pto-request-show-history=true");
          }
          else{
            this.showPTORequestHistory=false;
            console.log("pto-request-show-history=false");
          }
      });
  }

  setupPTOForm() {
    this.ptoForm = this.fb.group({
      ptoDate: ['', Validators.required],
      range: this.fb.group({
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
      }),
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      dayTotal: { value: 0, disabled: true },
      note: '',
      isRange: false,
      excludeWeekend: false
    }, {validators: [payPeriodValidator(new Date(this.payPeriod.pay_period_end_date)), ptoBalanceValidator(this.userProfile.ptoBalance)]});
    this.ptoForm.get('range').disable();
  }

  toggleIsRange(): void {
    if(this.ptoForm.get('isRange').value) {
      this.ptoForm.get('ptoDate').disable();
      this.ptoForm.get('range').enable();
    } else {
      this.ptoForm.get('range').disable();
      this.ptoForm.get('ptoDate').enable();
    }
  }

  calcDayTotal(): void {
    var total = 0;
    var startDate = new Date(this.ptoForm.get('range').get('startDate').value);
    var endDate = new Date(this.ptoForm.get('range').get('endDate').value);

    while(startDate <= endDate) {
      if(!this.ptoForm.value.excludeWeekend || (startDate.getDay() != 0 && startDate.getDay() != 6)) {
        total++;        
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    this.ptoForm.get('dayTotal').setValue(total);
  }

  onSubmit(form: UntypedFormGroup): void {
    console.log('submitting', form.value)
    this.loading = true;
    let ptoHoursMins = Number(form.value.minutes) > 1 ? Number(form.value.hours + '.5') : Number(form.value.hours);

    const ptoDate = !form.value.isRange ? form.value.ptoDate?.format(MY_FORMATS.parse.dbInput) : null;
    const start = form.value.isRange ? form.get('range').value.startDate?.format(MY_FORMATS.parse.dbInput) : null;
    const end = form.value.isRange ? form.get('range').value.endDate?.format(MY_FORMATS.parse.dbInput) : null;

    console.log("PTO Date: ", ptoDate, " Start: ", start, " End: ", end);

    if (this.payPeriod.isSet) {
      const data = form.value.isRange ?
      {
        employee_id: this.user.employee_id,
        PTORequestStartDate: start,
        PTORequestEndDate: end,
        PTORequestExcludeWeekends: form.value.excludeWeekend,
        PTORequestHours: ptoHoursMins,
        PTORequestNote: form.value.note
      } : 
      {
        employee_id: this.user.employee_id,
        PTORequestDate: ptoDate,
        PTORequestHours: ptoHoursMins,
        PTORequestNote: form.value.note
      };
      
      //set message
      this.message = `Saving...`;
      console.log("PTO Data: ", data);

      this.ptoService.save(data, form.value.isRange).pipe(
        takeUntil(this.unsubscriber),
        finalize(() => {this.loading = false; this.message = null;}),
        catchError(err => throwError(err))
      ).subscribe(result => {
        var snackbarMessage = "";
        console.log("Result: ", result);
        if(result) {
          if(JSON.stringify(result).includes("ERROR")) {
            snackbarMessage = result.replace("ERROR: ", "");
          } else {
            snackbarMessage = "PTO Request Success";
          }
        } else {
          snackbarMessage = "PTO Request Failure";
        }
        this.snackbar.open(snackbarMessage, "Dismiss");
        this.router.navigate(['demographics']);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}

// Verify Date is not during the current Pay Period
function payPeriodValidator(periodEnd: Date): ValidatorFn {
  return (c: AbstractControl): {[key: string]: boolean} | null => {
    var ptoDate = null;
    if(c.get('isRange').value && c.get('range').get('startDate').value) {
      ptoDate = new Date(c.get('range').get('startDate').value);
    } else if(c.get('ptoDate').value) {
      ptoDate = new Date(c.get('ptoDate').value);
    }
    var valid = ptoDate ? periodEnd < ptoDate : true;
    return !valid ? {'payPeriod': true} : null;
  }
}

// Verify PTO Total <= PTO Balance
function ptoBalanceValidator(ptoBalance: number): ValidatorFn {
  return (c: AbstractControl): {[key: string]: boolean} | null => {
    var valid = false;

    // If Is Range, find number of PTO Days and multiply by hours and compare to ptoBalance
    if(c.get('isRange').value) {
      valid = c.get('dayTotal').value * Number(c.get('hours').value) <= ptoBalance;
    // If Is Not Range, simply compare hours to ptoBalance
    } else {
      valid = Number(c.get('hours').value) <= ptoBalance;
    }
    return !valid ? {'ptoBalance': true} : null;
  }
}