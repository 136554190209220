<mat-spinner strokeWidth="3" [diameter]="50" id="loading"></mat-spinner>
<div fxFlex fxLayout="column" class="content-wrap">
  <!-- Toolbar -->
  <mat-toolbar>
    <app-menu-button fxLayout="column" class="menu-button"></app-menu-button>
    <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="space-between center">
      <h2 class="title">Schedule</h2>
      <mat-button-toggle-group [value]="viewSelected">
        <mat-button-toggle value="period"
                          (click)="setPeriodView('period')">Period</mat-button-toggle>
        <mat-button-toggle value="month"
                          (click)="setView(CalendarView.Month)" id="buttonMonth">Month</mat-button-toggle>
        <mat-button-toggle value="week"
                          (click)="setView(CalendarView.Week)">Week</mat-button-toggle>
        <mat-button-toggle value="day"
                          (click)="setView(CalendarView.Day)">Day</mat-button-toggle>
      </mat-button-toggle-group>

      <div fxLayout="row" fxHide fxShow.gt-sm *ngIf="periodView !== 'period'">
        <button mat-icon-button class="icon favorite-icon backButton" (click)="closeActiveDay()" mwlCalendarPreviousView
                [view]="view" [(viewDate)]="viewDate">
          <mat-icon class="icon-chevron-left"></mat-icon>
        </button>
        <h3 class="period">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        <button mat-icon-button class="icon favorite-icon backButton" (click)="closeActiveDay()" mwlCalendarNextView
                [view]="view" [(viewDate)]="viewDate">
          <mat-icon class="icon-chevron-right"></mat-icon>
        </button>
      </div>

      <div fxLayout="row" fxHide fxShow.gt-sm *ngIf="periodView === 'period'">
        <button mat-icon-button class="icon favorite-icon backButton"
                (click)="weeks2(payPeriodStartDate, payPeriodEndDate, 14, '-')">
          <mat-icon class="icon-chevron-left"></mat-icon>
        </button>
        <h3 class="period">
          {{ payPeriodStartDate | date: 'MMM d' }} - {{ payPeriodEndDate | date: 'MMM d, y' }}
        </h3>
        <button mat-icon-button class="icon favorite-icon backButton"
                (click)="weeks2(payPeriodStartDate, payPeriodEndDate, 14, '+')">
          <mat-icon class="icon-chevron-right"></mat-icon>
        </button>
      </div>

      <button mat-stroked-button class="today-button" *ngIf="periodView !== 'period'"
              fxHide fxShow.gt-sm mwlCalendarToday [(viewDate)]="viewDate">
        Today
      </button>

      <button mat-stroked-button class="today-button" fxHide fxShow.gt-sm
              *ngIf="periodView === 'period'" (click)="getToday()">
        Today
      </button>

      <button *ngIf="periodView === 'period' || view === 'month'" [disabled]="!hasAvailableData()" mat-button class="print-button primary-color" (click)="downloadAsPDF()">
        <mat-icon class="icon-print"></mat-icon>
        <span>Print</span>
      </button>
    </div>
  </mat-toolbar>

  <!-- Content -->
  <div fxFlex class="content" *ngIf="!loading">
    <div [ngSwitch]="periodView" class="calendar-wrap">
      <div *ngSwitchCase="'period'">
        <mat-accordion class="period-accordion">
          <div class="accordion-item" *ngFor="let day of schedulePeriod | filterDate:payPeriodStartDate:payPeriodEndDate">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    <div fxFlex="50" *ngIf="(day.date | date: 'M/d/yy') === (newDate | date: 'M/d/yy')">
                      Today
                    </div>
                    <div fxFlex="50" *ngIf="(day.date | date: 'M/d/yy') !== (newDate | date: 'M/d/yy')">
                      {{day.date | date: 'MMM d'}}
                    </div>
                    <div fxFlex="50" class="total-hrs">
                      <span class="balance-value">{{day.totalScheduledHrs | numberToTime: 'd'}}</span>
                      <span class="label-hrs">hours schd.&nbsp;&nbsp;/</span>
                      <span class="balance-value">&nbsp;&nbsp;{{day.totalWorkedHrs | numberToTime: 's'}}</span>
                      <span class="label-hrs">hours wrkd.</span>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list role="list" class="period-list expansion-period-list">
                <mat-list-item role="listitem">
                  <div fxFlex fxLayout="row">
                    <div fxFlex="50">PTO Hours Used:</div>
                    <div fxFlex="50" fxLayoutAlign="end center">{{day.totalPTO | numberToTime: 'd'}} hours</div>
                  </div>
                </mat-list-item>
                <mat-list-item role="listitem">
                  <div fxFlex fxLayout="row">
                    <div fxFlex="50">Bonus:</div>
                    <div fxFlex="50" fxLayoutAlign="end center">{{day.totalBonus | currency}}</div>
                  </div>
                </mat-list-item>
                <mat-list-item role="listitem">
                  <div fxFlex fxLayout="row">
                    <div fxFlex="50">Deductions:</div>
                    <div fxFlex="50" fxLayoutAlign="end center">- {{day.totalDeduction | currency}}</div>
                  </div>
                </mat-list-item>
                <mat-list-item role="listitem">
                 <div fxFlex fxLayout="row">
                   <div fxFlex="50">Indirect:</div>
                   <div fxFlex="50" fxLayoutAlign="end center">{{day.totalIndirect | numberToTime: 'd'}}</div>
                 </div>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
            <mat-list role="list" class="period-list">
              <mat-list-item role="listitem" *ngFor="let shift of day.scheduledShifts">
                <div fxFlex fxLayout="row">
                  <div fxFlex="50">{{shift.position}}</div>
                  <div fxFlex="50" fxLayoutAlign="end center" class="time-period">
                    <span class="time-start">{{shift.start | date: 'h:mm a'}}</span>
                    &mdash;
                    <span class="time-end">{{shift.end | date: 'h:mm a'}}</span>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-accordion>

      </div>
      <div [ngSwitch]="view">
        <div *ngIf="!periodView">
          <mwl-calendar-month-view
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="scheduleCalendar"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
          >
          </mwl-calendar-month-view>
          <mwl-calendar-week-view
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [events]="scheduleCalendar"
            [refresh]="refresh"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
          >
          </mwl-calendar-week-view>
          <mwl-calendar-day-view
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [events]="scheduleCalendar"
            [refresh]="refresh"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
          >
          </mwl-calendar-day-view>
        </div>
      </div>
    </div>

  </div>

  <!-- Toolbar Bottom -->
  <mat-toolbar fxHide.gt-sm class="toolbar-bottom">
      <div fxFlex fxLayout="row" fxLayoutAlign="center center" *ngIf="periodView !== 'period'">
        <button mat-icon-button class="icon favorite-icon backButton" (click)="closeActiveDay()" mwlCalendarPreviousView
                [view]="view" [(viewDate)]="viewDate">
          <mat-icon class="icon-chevron-left"></mat-icon>
        </button>
        <h3 class="period">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        <button mat-icon-button class="icon favorite-icon backButton" (click)="closeActiveDay()" mwlCalendarNextView
                [view]="view" [(viewDate)]="viewDate">
          <mat-icon class="icon-chevron-right"></mat-icon>
        </button>
      </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="center center" *ngIf="periodView === 'period'">
      <button mat-icon-button class="icon favorite-icon backButton"
              (click)="weeks2(payPeriodStartDate, payPeriodEndDate, 14, '-')">
        <mat-icon class="icon-chevron-left"></mat-icon>
      </button>
      <h3 class="period">
        {{ payPeriodStartDate | date: 'MMM d' }} - {{ payPeriodEndDate | date: 'MMM d, y' }}
      </h3>
      <button mat-icon-button class="icon favorite-icon backButton"
              (click)="weeks2(payPeriodStartDate, payPeriodEndDate, 14, '+')">
        <mat-icon class="icon-chevron-right"></mat-icon>
      </button>
    </div>

    <button mat-stroked-button class="today-button" mwlCalendarToday [(viewDate)]="viewDate" *ngIf="periodView !== 'period'">
      Today
    </button>

    <button mat-stroked-button class="today-button" *ngIf="periodView === 'period'" (click)="getToday()">
      Today
    </button>
  </mat-toolbar>
</div>
