import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../core/api.service';

@Injectable({
    providedIn: 'root'
})
export class PtoRequestService {

    constructor(private api: ApiService) {
    }

    getCurrentPayPeriod(employee_id): Observable<any> {
        return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/CurrentPayPeriod/${employee_id}`);
    }

    save(data, isRange): Observable<any> {
        var apiRequest = isRange ?
            `api/v1/EmployeeSelfService/Employee/SavePTORequestRange/` :
            `api/v1/EmployeeSelfService/Employee/SavePTORequest/`;
        return this.api.postRequest(apiRequest, data);
    }
}

