import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../core/material.module';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';

import {NotFoundComponent} from './not-found/not-found.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MaterialModule,
    ],
    exports: [],
    declarations: [
        NotFoundComponent,
    ],
    providers: []
})
export class SharedModule {
}
