
<div fxFlex fxLayout="column" class="content-wrap">
  <!-- Toolbar -->
  <mat-toolbar>
    <button mat-button class="back-button" color="primary" routerLink="./../schedule">
      <mat-icon class="icon-chevron-left"></mat-icon>
      <span fxHide.gt-sm>Schedule</span>
    </button>

    <div fxFlex>
      <h2 class="title">Detail View</h2>
    </div>

<!--    <button mat-button class="print-button primary-color">-->
<!--      <mat-icon class="icon-print"></mat-icon>-->
<!--      <span fxHide fxShow.gt-sm>Print</span>-->
<!--    </button>-->
  </mat-toolbar>

  <!-- Content -->
  <div fxFlex class="content">
    <div fxLayout="column" fxLayout.gt-sm="row" class="user-info-main">
      <div fxFlex.gt-sm="33" fxLayout="row">
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">Name</span>
          <p>{{scheduleEvent.name}}</p>
        </div>
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">ID</span>
          <p>{{scheduleEvent.id}}</p>
        </div>
      </div>
      <div fxFlex.gt-sm="33" fxFlexOffset.gt-sm="15" fxLayout="row">
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">Date</span>
          <p>{{ scheduleEvent.date | date:'mediumDate'}}</p>
        </div>
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">Position</span>
          <p>{{scheduleEvent.position}}</p>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" class="user-info">
      <div *ngFor="let detailItem of scheduleEvent.data" class="user-info-item">
        <span class="label">{{detailItem.detailText}}</span>
        <p>{{detailItem.value}}</p>
      </div>
    </div>
  </div>
</div>
