import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {MessagesService} from './messages.service';
import {DataService} from '../core/data.service';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  loading = false;
  userID = '';
  messages = {} as any;
  sortMessages = {} as any;
  totalNoRead: number;
  matcher: MediaQueryList;

  constructor(private router: Router,
              public mediaMatcher: MediaMatcher,
              private messagesService: MessagesService,
              private dataService: DataService) {
  }

  ngOnInit(): void {
    this.matcher = this.mediaMatcher.matchMedia('(max-width: 959px)');

    this.loading = true;
      setTimeout(() => {
        this.messagesService.getUserData()
          .pipe(
           takeUntil(this.unsubscriber)
         )
         .subscribe(user => {
           this.userID = user.employee_id;
           this.messagesService.getMessages(this.userID)
             .pipe(
                takeUntil(this.unsubscriber),
                finalize(() => this.loading = false)
              )
              .subscribe((messages) => {
                this.sortMessages = messages;
                this.messages = this.sortMessages.sort(this.sortFunction);
               this.totalNoRead = this.messages.filter((obj) => obj.message_read === false).length;
              });
          });
    }, 2000)
  }

  sortFunction(a, b): number {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateA > dateB ? -1 : 1;
  }

  saveBody(message: any): void {
    if (!this.matcher.matches) {
      this.dataService.setMessage(message);
    }

    if (this.matcher.matches) {
      setTimeout(() => {
        this.dataService.setMessage(message);
      }, );
    }

    if (!message.message_read) {
      this.messagesService.setMessageRead(message.id)
        .pipe(
          takeUntil(this.unsubscriber),
        )
        .subscribe(response => {
          if (response) {
            this.messages.forEach(item => {
              if (item.id === message.id) {
                item.message_read = true;
                this.totalNoRead = this.messages.filter((obj) => obj.message_read === false).length;
              }
            });
          }
        });
    }
    if (this.matcher.matches) {
      this.router.navigate(['../messages/detail']);
    }
  }

  ngOnDestroy(): void {
    this.dataService.removeMessage();
    this.dataService.removeMessage();
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
