import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../core/data.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-message-body',
  templateUrl: './message-body.component.html',
  styleUrls: ['./message-body.component.scss']
})
export class MessageBodyComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  loading = false;
  message = {} as any;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.loading = true;

    this.dataService.messageDetail
      .pipe(
        takeUntil(this.unsubscriber),
      )
      .subscribe(value => {
        this.message = value;
        this.loading = false;
      });
  }

  goBack(): void {
    window.history.back();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
