import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DataService {
  public messageDetail: BehaviorSubject<any> = new BehaviorSubject<any>({});

  setMessage(value: any): void {
    this.messageDetail.next(value);
  }

  removeMessage(): void {
    this.messageDetail.next({});
  }
}
