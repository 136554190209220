import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import {User} from '../shared/user.interface';
import {Profile, ProfileEmail, ProfilePhone, ProfilePosition} from '../shared/profile.interface';
import { ApiService } from '../core/api.service';
import { FeatureFlagService } from '../featureflag.service';
import { DemographicsComponent } from './demographics.component';

@Injectable({
  providedIn: 'root'
})
export class DemographicsService {
  featureFlag = false;

  constructor(private api: ApiService) {
  }

  getUserData(): Observable<User> {
    return this.api.getRequest(`api/v1/user/Username`); 
  }

  getProfile(id: string): Observable<Profile> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/${id}`);
  }

  getProfilePhone(id: string): Observable<ProfilePhone[]> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/Phone/${id}`);
  }

  getProfileEmail(id: string): Observable<ProfileEmail> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/Email/${id}`);
  }

  getPosition(id: string): Observable<ProfilePosition> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/Position/${id}`);
  }

  getFeatureFlag(locationshortname: string): Observable<boolean> {
    return this.api.getRequest(`api/v1/FeatureFlagService/GetFeatureFlag/${locationshortname}`);
  }
}
