<mat-spinner strokeWidth="3" [diameter]="50" *ngIf="loading"></mat-spinner>
<div fxFlex fxLayout="column" class="content-wrap">
  <!-- Toolbar -->
  <mat-toolbar fxLayout="column" fxHide.gt-sm>
    <div fxLayout="row">
      <button mat-button class="back-button" color="primary" routerLink="./../../messages">
        <mat-icon class="icon-chevron-left"></mat-icon>
        <span>Messages</span>
      </button>

      <div fxFlex>
        <h2 class="title"></h2>
      </div>
    </div>

    <div fxFlex class="top-message-title" *ngIf="message && !loading">
      <h2 class="message-subject">{{message.subject}}</h2>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="message-sender">{{message?.from?.name}}</div>
        <div class="message-date">{{message.date | date: 'mediumDate'}}</div>
      </div>
    </div>
  </mat-toolbar>

  <!-- Content -->
  <div class="content">
    <div *ngIf="message && !loading">
      <h2 class="message-subject" fxHide fxShow.gt-sm>{{message.subject}}</h2>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxHide fxShow.gt-sm>
        <div class="message-sender">{{message?.from?.name}}</div>
        <div class="message-date">{{message.date | date: 'mediumDate'}}</div>
      </div>

      <div class="message-text">
        <p>{{message.message}}</p>
      </div>
    </div>

  </div>
</div>
