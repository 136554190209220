<mat-spinner strokeWidth="3" [diameter]="50" *ngIf="loading"></mat-spinner>
<div fxFlex fxLayout="column" class="content-wrap" *ngIf="profile && !loading">
  <!-- Toolbar -->
  <mat-toolbar>
    <app-menu-button fxLayout="column" class="menu-button"></app-menu-button>
    <div fxLayout="column" class="profile-info">
      <div fxLayout="row" fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start start" class="title-wrap">
        <h2 class="title">{{profile.firstName}} {{profile.lastName}}</h2>
        <button mat-icon-button (click)="logout()" fxHide.gt-sm>
          <mat-icon class="icon-sign-out" aria-label="Logout"></mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start start" class="subtitle">
        <span>{{profile.userName}}</span>
        <span class="dot-divider">•</span>
        <span>Employee ID: {{profile.empID}}</span>
      </div>
    </div>
  </mat-toolbar>

  <!-- Content -->
  <div fxFlex class="content">
    <div fxLayout="row" class="user-info-main">
      <div fxFlex="50" fxFlex.gt-sm="33" fxLayout="column" fxLayout.gt-sm="row">
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">Date of Birth</span>
          <p *ngIf="!profile.dob">-</p>
          <p>{{ profile.dob | date:'mediumDate'}}</p>
        </div>
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">Status</span>
          <p *ngIf="!profile.status_description">-</p>
          <p>{{profile.status_description}}</p>
        </div>
      </div>
      <div fxFlex="50" fxFlex.gt-sm="33" fxFlexOffset.gt-sm="15" fxLayout="column" fxLayout.gt-sm="row">
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">Hired</span>
          <p *ngIf="!profile.hireDate">-</p>
          <p>{{ profile.hireDate | date:'mediumDate'}}</p>
        </div>
        <div fxFlex="50" fxLayout="column" class="user-info-item">
          <span class="label">PTO Balance</span>
          <p *ngIf="!profile.ptoBalance">-</p>
          <p *ngIf="profile.ptoBalance">
            <span class="balance-value">{{profile.ptoBalance | numberToTime }}</span>
            hours
          </p>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" class="user-info">
      <div fxFlex.gt-sm="33" fxLayout.gt-sm="column">
        <div class="user-info-item">
          <span class="label">Address</span>
          <p *ngIf="!profile.address">-</p>
          <p>{{profile.address}}</p>
        </div>
        <div class="user-info-item">
          <span class="label">Phone Number</span>
          <p *ngIf="profilePhone.length == 0">-</p>
          <div *ngFor="let phone of profilePhone">
            <p fxLayout="row" fxLayoutAlign="space-between center">
              {{phone.phone_number}}
              <span class="accent-color">
                {{phone.phone_type}}
              </span>
            </p>
          </div>
<!--          <ng-container *ngFor="let phone of profilePhone">-->
<!--            <p fxLayout="row" fxLayoutAlign="space-between center">-->
<!--              {{phone.phone_number}}-->
<!--              <span class="accent-color" *ngIf="phone.phone_type">-->
<!--                {{phone.phone_type}}-->
<!--              </span>-->
<!--            </p>-->
<!--          </ng-container>-->
        </div>
      </div>
      <div fxFlex.gt-sm="33" fxFlexOffset.gt-sm="15" fxLayout.gt-sm="column">
        <div class="user-info-item">
          <span class="label">Email</span>
          <p *ngIf="!profileEmail.employee_address">-</p>
          <p fxLayout="row" fxLayoutAlign="space-between center">
            {{profileEmail.employee_address}}
            <span class="accent-color" *ngIf="profileEmail?.email_type">
              {{profileEmail.email_type}}
            </span>
          </p>
<!--          <ng-container *ngFor="let email of profileEmail">-->
<!--            <p fxLayout="row" fxLayoutAlign="space-between center">-->
<!--              {{email.employee_address}}-->
<!--              <span class="accent-color" *ngIf="email.email_type">-->
<!--                {{email.email_type}}-->
<!--              </span>-->
<!--            </p>-->
<!--          </ng-container>-->
        </div>
        <div class="user-info-item">
          <span class="label">Position</span>
          <p *ngIf="!profilePosition.length">-</p>
          <ng-container *ngFor="let position of profilePosition">
            <p fxLayout="row" fxLayoutAlign="space-between center">
              {{position.position_desc}}
              <span class="accent-color" *ngIf="position.position_type">
                {{position.position_type}}
              </span>
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
