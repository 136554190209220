import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import * as  flagsmith from 'flagsmith-nodejs';

@Injectable({
    providedIn: 'root'
  })
  export class FeatureFlagService{
    current_environment = environment.flagsmithenvID;
    constructor(){
        flagsmith.init({
            environmentID: `${environment.flagsmithenvID}`,
        });
        console.log("********************FEATURE FLAG Initialized");
    }
    async getFlagValue(flag:string):Promise<boolean>{
        let retval = false;
        await flagsmith.hasFeature(flag)
        .then((featureEnabled) => {
            if (featureEnabled) {
              retval=true;
              console.log("**********************Feature Flag - " + flag + " is true");
            }
            else{
              retval=false;
              console.log("**********************Feature Flag - " + flag + " is false");
            }
        });
        return retval;
    }
    async getFlagValueByUser(flag:string,current_user_name:string):Promise<boolean>{
      console.log("**********************Feature User - ",current_user_name );
      let retval = false;
      await flagsmith.hasFeature(flag,current_user_name)
      .then((featureEnabled) => {
          if (featureEnabled) {
            retval=true;
            console.log("**********************Feature Flag - " + flag + " is true");
          }
          else{
            retval=false;
            console.log("**********************Feature Flag - " + flag + " is false");
          }
      });
      return retval;
    } 
  }