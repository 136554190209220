import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Message} from '../shared/message.interface';
import {User} from '../shared/user.interface';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private api: ApiService) {
  }

  getUserData(): Observable<User> {
    return this.api.getRequest(`api/v1/user/Username`);
  }

  getMessages(id: string): Observable<Message> {
    return this.api.getRequest(`api/v1/EmployeeSelfService/Employee/Message/${id}`);
  }

  setMessageRead(id: string): Observable<any> {
    return this.api.postRequest(`api/v1/EmployeeSelfService/Employee/MarkMessageRead/${id}`, {});
  }
}
