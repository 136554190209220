<mat-spinner strokeWidth="3" [diameter]="50" *ngIf="loading"></mat-spinner>
<div fxFlex fxLayout="column" class="content-wrap" *ngIf="!loading">
    <!-- Toolbar -->
    <mat-toolbar>
      <app-menu-button fxLayout="column" class="menu-button"></app-menu-button>
      <h2 class="title">Shift Bidding</h2>
      <button mat-button class="options-button" (click)="options()">
        <mat-icon class="icon-options"></mat-icon><span>Options</span>
      </button>
    </mat-toolbar>
    
    <!-- Content -->
    <mat-tab-group *ngIf="profileSetupComplete" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" mat-align-tabs="center">
      <mat-tab label="Open Shifts">
        <ng-template matTabContent>
          <mat-accordion *ngIf="openShiftsCurrent.length + declinedShifts.length > 0">
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let shift of openShiftsCurrent">
              <mat-expansion-panel-header class="open_shift_new toggle_color">
                <mat-panel-title>
                  {{shift.position}}
                </mat-panel-title>
                <mat-panel-description>
                  {{shift.date | date: 'mediumDate'}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="shift_info">
                <p>{{shift.shift_begin | date: 'shortTime'}} - {{shift.shift_end | date: 'shortTime'}}</p>
                <p>{{shift.bonus_amt | currency}} Bonus</p>
                <p>{{shift.employee_response}}</p>
              </div>
              <button mat-raised-button color="primary" (click)="requestShift(shift)">
                Request
              </button>
              <button mat-raised-button color="accent" (click)="declineShift(shift)">
                Decline
              </button>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let shift of declinedShifts">
              <mat-expansion-panel-header class="open_shift_declined toggle_color">
                <mat-panel-title>
                  {{shift.position}}
                </mat-panel-title>
                <mat-panel-description>
                  {{shift.date | date: 'mediumDate'}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="shift_info">
                <p>{{shift.shift_begin | date: 'shortTime'}} - {{shift.shift_end | date: 'shortTime'}}</p>
                <p>{{shift.bonus_amt | currency}} Bonus</p>
                <p>{{shift.employee_response}}</p>
              </div>
              <button mat-raised-button color="primary" (click)="requestShift(shift)">
                Request
              </button>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="no_content" *ngIf="openShiftsCurrent.length + declinedShifts.length == 0">
            <p>No new open shifts</p>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Pending">
        <ng-template matTabContent>
          <mat-accordion *ngIf="pendingShifts.length > 0">
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let shift of pendingShifts">
              <mat-expansion-panel-header class="open_shift_pending toggle_color">
                <mat-panel-title>
                  {{shift.position}}
                </mat-panel-title>
                <mat-panel-description>
                  {{shift.date | date: 'mediumDate'}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="shift_info">
                <p>{{shift.shift_begin | date: 'shortTime'}} - {{shift.shift_end | date: 'shortTime'}}</p>
                <p>{{shift.bonus_amt | currency}} Bonus</p>
                <p>{{shift.employee_response}}</p>
              </div>
              <button mat-raised-button color="accent" (click)="declineShift(shift)">
                Cancel
              </button>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="no_content" *ngIf="pendingShifts.length == 0">
            <p>No pending shifts</p>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="History">
        <ng-template matTabContent>
          <mat-accordion *ngIf="shiftHistory.length > 0">
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let shift of shiftHistory">
              <mat-expansion-panel-header class="open_shift_history toggle_color">
                <mat-panel-title>
                  {{shift.position}}
                </mat-panel-title>
                <mat-panel-description>
                  {{shift.date | date: 'mediumDate'}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="shift_info">
                <p>{{shift.shift_begin | date: 'shortTime'}} - {{shift.shift_end | date: 'shortTime'}}</p>
                <p>{{shift.bonus_amt | currency}} Bonus</p>
                <p>{{shift.employee_response}}</p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="no_content" *ngIf="shiftHistory.length == 0">
            <p>No shift history</p>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div *ngIf="!profileSetupComplete" class="no_content">
      <p>Please set up your Shift Bid Options</p>
      <button mat-raised-button color="accent" (click)="options()">
        Take me to options
      </button>
    </div>
</div>