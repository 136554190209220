import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ApiService } from './core/api.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private api: ApiService) {
  }

  getAuthentication(): Observable<any> {
    return this.api.getRequest(`api/Authentication`);
  }
}
